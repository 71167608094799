import { QuantityUnit } from './unit.interface';
import { ElementCategoryID } from './element_categories.interface';
import { ExpressionValue, OneOfElements } from './project.interface';
import { Recipe, RecipeID } from './recipe.interface';
import {
  PartialRecord,
  Replace,
  SemiPartial,
  SemiRequired,
} from './type_helpers.interface';
import { FactoryCountExpression } from './element_factory_helpers.interface';

export type ElementPropertyID = string;
export type ElementPropertySourceID = ElementCategoryID | RecipeID;
export enum ElementPropertySource {
  Category = 'category_id',
  Recipe = 'recipe_id',
}

export type ElementSelectPropertyCountType = string | number;

export interface IElementPropertyOption {
  label: string;
  value: ElementSelectPropertyCountType;
}

export enum ElementPropertyInputType {
  Expression = 'expression',
  Select = 'select',
  Switch = 'switch',
}

export interface IElementPropertySourceIdProperties {
  [ElementPropertySource.Category]?: ElementCategoryID;
  [ElementPropertySource.Recipe]?: RecipeID;
}

interface IElementPropertyBase extends IElementPropertySourceIdProperties {
  /**
   * How the property should be displayed,
   * with expression input, select etc.
   * Should default to expression if undefined
   */
  type?: ElementPropertyInputType;
  id: ElementPropertyID;
  name: ElementPropertyName | string;

  /**
   * If hidden this property will be available
   * in expressions as normal properties but not visible in UI
   */
  hidden?: boolean;

  /**
   * If readonly property can't be edited by the user.
   */
  readonly?: boolean;

  /**
   * When this is true this property will be available when the project variables resolves (for storeys, activitites etc.).
   * If true this property can't reference any project variables in the expression
   */
  resolveBeforeProjectVariables?: boolean;

  /**
   * If defined this will be used as tooltip title in UI
   */
  description?: string;
}

export interface IElementExpressionProperty extends IElementPropertyBase {
  type?: ElementPropertyInputType.Expression;
  count?: ExpressionValue;

  /**
   * If count is not defined by the user we can define a fallback value
   * To use instead. Will show up in UI as a gray placeholder.
   */
  fallbackCount?: ExpressionValue;

  /**
   * If a property have conditional properties referenced in expressions
   * we need to define the default values for those properties.
   */
  expressionDependenciesDefaults?: Record<string, number>;

  /**
   * If the property belongs to a root element, this can be used to disable inheritance
   * in order to not clear default expression values.
   */
  disableInheritance?: boolean;

  unit: QuantityUnit;

  /**
   * If defined throw error if count is lower than this min
   */
  min?: number;

  /**
   * If defined throw error if count is larget than this max
   */
  max?: number;
}

export interface IElementSelectProperty extends IElementPropertyBase {
  type: ElementPropertyInputType.Select;
  count?: ElementSelectPropertyCountType;
  fallbackCount?: ElementSelectPropertyCountType;
  options: IElementPropertyOption[];
}

export interface IElementSwitchProperty extends IElementPropertyBase {
  type: ElementPropertyInputType.Switch;
  count?: boolean;
  fallbackCount?: boolean;
}

export type IElementPropertyParent = OneOfElements | Recipe;

export type IElementProperty =
  | IElementExpressionProperty
  | IElementSelectProperty
  | IElementSwitchProperty;

/**
 * Which defined values can exist in count property
 */
export type ElementPropertyCountType = Required<IElementProperty>['count'];

/**
 * Use ElementPropertyInputTypeMap to get correct type of properties
 */
export type ElementPropertyInputTypeMap = {
  [ElementPropertyInputType.Expression]: IElementExpressionProperty;
  [ElementPropertyInputType.Select]: IElementSelectProperty;
  [ElementPropertyInputType.Switch]: IElementSwitchProperty;
};

/**
 * Use ElementPropertyCountValueMap to get correct primitive value of count property.
 * Note: Better to use ElementPropertyCountValue to get the correct type
 */
type ElementPropertyCountValueMap = {
  [ElementPropertyInputType.Expression]: number;
  [ElementPropertyInputType.Select]: Required<IElementSelectProperty>['count'];
  [ElementPropertyInputType.Switch]: Required<IElementSwitchProperty>['count'];
};

/**
 * All possible resolved values for count property
 */
export type ElementPropertyCountValues = ElementPropertyCountValueMap[
  | ElementPropertyInputType.Expression
  | ElementPropertyInputType.Select
  | ElementPropertyInputType.Switch];

/**
 * Correct return type based on propertyType when using getPropertyCountValue
 */
export type ElementPropertyCountValue<
  T extends keyof ElementPropertyCountValueMap | undefined = undefined,
> = T extends string
  ? ElementPropertyCountValueMap[T]
  : ElementPropertyCountValueMap[ElementPropertyInputType.Expression];

/**
 * Properties defined by us. Note that names must be valid variable names.
 */
export enum ElementPropertyName {
  Span = 'spännvidd',
  BeamWidth = 'balkbredd',
  BeamHeight = 'balkhöjd',
  CenterToCenter = 'centrumavstånd',
  ClimateClass = 'climate_class',
  WoodClass = 'wood_class',
  RoofPitch = 'roof_pitch',
}

export type IElementPropertyFactoryOption =
  | ElementSelectPropertyCountType
  | SemiRequired<IElementPropertyOption, 'label'>;

export type IFactorySelectProperty = SemiPartial<
  Replace<
    IElementSelectProperty,
    {
      options: IElementPropertyFactoryOption[];
    }
  >,
  'type'
>;

export type IFactoryExpressionProperty = Partial<
  Replace<
    IElementExpressionProperty,
    {
      count: FactoryCountExpression;

      /**
       * Fallback to use when count is not defined
       */
      fallbackCount: FactoryCountExpression;
    }
  >
>;

export type IFactorySwitchProperty = SemiPartial<
  IElementSwitchProperty,
  'type'
>;

export type IFactoryProperty =
  | IFactoryExpressionProperty
  | IFactorySelectProperty
  | IFactorySwitchProperty;

/**
 * Use FactoryPropertyInputTypeMap to get correct interface based on property type
 */
export type FactoryPropertyInputTypeMap = {
  [ElementPropertyInputType.Expression]: IFactoryExpressionProperty;
  [ElementPropertyInputType.Select]: IFactorySelectProperty;
  [ElementPropertyInputType.Switch]: IFactorySwitchProperty;
};

export type CreatedElementProperty<
  T extends keyof FactoryPropertyInputTypeMap | undefined,
> = T extends string
  ? ElementPropertyInputTypeMap[T]
  : ElementPropertyInputTypeMap[ElementPropertyInputType.Expression];

export type PropertyCountValueRecord = PartialRecord<
  string,
  ElementPropertyCountValues
>;
