import React from 'react';
import { Button, Tooltip } from '@mui/material';
import { useSelectedVersion } from '../store/ui';
import { makeStyles } from 'tss-react/mui';
import { useIsReadonly } from '../hooks/user.hook';
import VersionMenu from '../components/VersionMenu';
import { Row } from '../components/ElementList/Row';
import { RowCell } from '../components/ElementList/RowCell';
import {
  ROOT_CELL_WIDTH,
  LIST_SPACING,
  CONTENT_CELL_WIDTH,
  ELEMENT_LIST_ITEM_HEIGHT,
  CONTENT_CELL_WIDTH_RESPONSIVE,
  ROOT_CELL_WIDTH_RESPONSIVE,
} from '../components/ElementList/list.constants';
import {
  LIST_BORDER,
  useListRowStyles,
} from '../components/ElementList/list.style';
import { Add } from '@mui/icons-material';
import { useAddProposal } from '../hooks/proposals.hook';
import { costEmissionIcons } from '../components/Icon';
import MultiLineTooltip from '../components/MultiLineTooltip';

const ProjectViewHeader: React.FC = () => {
  const { classes: listClasses } = useListRowStyles();
  const { classes } = useStyles();
  const version = useSelectedVersion();
  const readonly = useIsReadonly();
  const addProposal = useAddProposal();

  // If statement for undefined selectedVersion.id in "ADD BUTTON".
  if (!version) {
    return null;
  }

  return (
    <>
      {/* Make sure this row the same layout as regular element list */}
      <Row
        paddingTop={1}
        padding={true}
        spacing={LIST_SPACING}
        classes={listClasses}
      >
        <RowCell
          width={ROOT_CELL_WIDTH.CONTENT}
          borderRight={LIST_BORDER}
          paddingRight={LIST_SPACING}
        >
          <Row height={ELEMENT_LIST_ITEM_HEIGHT} spacing={LIST_SPACING}>
            {/* Version Menu */}
            <RowCell
              width={CONTENT_CELL_WIDTH.NAME}
              responsiveWidth={CONTENT_CELL_WIDTH_RESPONSIVE.NAME}
              paddingLeft={10}
              paddingRight={0}
            >
              <VersionMenu />
            </RowCell>

            {/* Add Proposal Button */}
            <RowCell width={CONTENT_CELL_WIDTH.ICON} align="center">
              {!readonly && (
                <Tooltip title="Add proposal" placement="bottom">
                  <Button
                    className={classes.addProposal}
                    onClick={() => addProposal()}
                  >
                    <Add fontSize="small" />
                  </Button>
                </Tooltip>
              )}
            </RowCell>
          </Row>
        </RowCell>

        {/* Bars placeholder */}
        <RowCell
          width={ROOT_CELL_WIDTH.BAR}
          responsiveWidth={ROOT_CELL_WIDTH_RESPONSIVE.BAR}
        ></RowCell>

        {/* CO2 Icon */}
        <RowCell width={CONTENT_CELL_WIDTH.UNIT} align="center">
          <MultiLineTooltip rows={['Emissions per GFA', 'kgCO2e / m2']}>
            <div>{costEmissionIcons.kgCO2e}</div>
          </MultiLineTooltip>
        </RowCell>

        {/* Cost Icon */}
        <RowCell width={CONTENT_CELL_WIDTH.UNIT} align="center">
          <MultiLineTooltip rows={['Cost per GFA', 'kSEK / m2']}>
            <div>{costEmissionIcons.kSEK}</div>
          </MultiLineTooltip>
        </RowCell>
      </Row>
    </>
  );
};

const useStyles = makeStyles()(({ palette, spacing }) => ({
  addProposal: {
    color: palette.text.secondary,
    paddingRight: spacing(1),
    paddingLeft: spacing(1),
    minWidth: 0,
  },
}));

export default ProjectViewHeader;
