import { last } from 'lodash';
import { useCallback, useMemo, useRef } from 'react';
import {
  findElement,
  flattenElements,
  getAllBuildingVersions,
  getChildElements,
  getElementChildrenDepth,
  getProductElementPathsRecord,
} from '../../../shared/helpers/recursive_element_helpers';
import {
  IBuildingVersion,
  IElement,
  OneOfChildElements,
  OneOfElements,
  OneOfListElements,
  OneOfParentElements,
} from '../../../shared/models/project.interface';
import { useProject } from '../store/project';
import {
  getSelectedVersion,
  useSelectedElement,
  useSelectedVersion,
} from '../store/ui';
import {
  ArrayOrSingle,
  ItemOrItemId,
} from '../../../shared/models/type_helpers.interface';
import { PathRecord } from '../../../shared/helpers/element_category_helpers';
import { useSortedFlattenedElements } from './filter-elements.hook';
import { getPathInFlatTree } from '../../../shared/helpers/tree.helpers';
import { isProjectInfoOrFolder } from '../../../shared/helpers/project-folder.helpers';
import { isDeactivated } from '../../../shared/helpers/element_helpers';

/**
 * Get the path, all parents excluding itself, to an element.
 * @param element
 * @returns
 */
export const useElementPath = (
  element?: OneOfListElements,
): OneOfParentElements[] => {
  const allElements = useSortedFlattenedElements();

  // Search in all versions, starting with the selected version
  return useMemo(() => {
    if (isProjectInfoOrFolder(element) || !element) {
      return [];
    }
    return [
      getSelectedVersion(true),
      ...(getPathInFlatTree(
        allElements,
        element,
        'elements',
      ) as OneOfParentElements[]),
    ];
  }, [allElements, element]);
};

/**
 * Get parent element of an element.
 * @param element
 * @returns
 */
export const useParentElement = (
  element: OneOfListElements,
): OneOfParentElements | undefined => last(useElementPath(element));

/**
 * Get all versions.
 * @returns
 */
export const useVersions = (): IBuildingVersion[] => {
  const project = useProject();
  return useMemo(() => getAllBuildingVersions(project), [project]);
};

/**
 * Get all versions, with the selectedVersion first.
 * This to increase performance since most operations are done on the selected version.
 * @returns
 */
export const useVersionsWithSelectedFirst = (): IBuildingVersion[] => {
  const versions = useVersions();
  const selected = useSelectedVersion();
  return useMemo(
    () =>
      selected
        ? [selected, ...versions.filter((v) => v !== selected)]
        : versions,
    [versions, selected],
  );
};

export const useElementById = (
  elementOrId: ItemOrItemId<OneOfElements> | undefined,
): OneOfElements | undefined => {
  const version = useSelectedVersion();
  return useMemo(() => {
    if (typeof elementOrId === 'string') {
      return findElement(version, elementOrId);
    }
    return elementOrId;
  }, [elementOrId, version]);
};

export const useElementChildren = <T extends OneOfListElements>(
  element: T | undefined,
): OneOfChildElements<T>[] => {
  // To not create a new array for empty elements
  const empty = useRef<OneOfChildElements<T>[]>([]);
  const children = getChildElements(element);
  return children.length > 0 ? children : empty.current;
};

export const useIsSelectedElement = (element?: OneOfElements): boolean => {
  const selected = useSelectedElement();
  return !!element && selected?.id === element.id;
};

export const useIsRootElement = (): ((element: IElement) => boolean) => {
  const selectedVersion = useSelectedVersion();

  if (!selectedVersion) {
    throw new Error("Couldn't find version");
  }
  return useCallback(
    (element) =>
      selectedVersion.elements.some((child) => child.id === element.id),
    [selectedVersion.elements],
  );
};

/**
 * Get how many levels of elements there are in an element.
 * 0 for empty, 1 for element with only root elements, and so on.
 * @param element
 * @returns
 */
export const useElementChildrenDepth = (element?: OneOfElements): number => {
  return useMemo(() => getElementChildrenDepth(element), [element]);
};

/**
 * Get how many levels of elements there are in the selected version.
 * 0 for empty version, 1 for version with only root elements, and so on.
 * @returns
 */
export const useSelectVersionDepth = (): number => {
  const selectedVersion = useSelectedVersion();
  return useElementChildrenDepth(selectedVersion);
};

export const useFlattenedElement = (
  element: OneOfElements | undefined,
): OneOfChildElements[] => {
  return useMemo(() => {
    return flattenElements(element);
  }, [element]);
};

/**
 * Get all children in an element recursively, excluding self.
 * @param element
 * @returns
 */
export const useAllChildren = (
  element: OneOfElements | undefined,
): OneOfChildElements[] => {
  const children = useElementChildren(element);

  return useMemo(() => {
    return flattenElements(...children);
  }, [children]);
};

/**
 * Get all elements in the selected version (recursive)
 * @returns
 */
export const useAllElementsInSelectedVersion = (): OneOfChildElements[] => {
  const version = useSelectedVersion();
  return useAllChildren(version);
};

export const useProductElementPathsRecord = (
  rootElements: ArrayOrSingle<OneOfElements> | undefined,
  getPathToAllElements = false,
): PathRecord =>
  useMemo(
    () => getProductElementPathsRecord(rootElements, getPathToAllElements),
    [getPathToAllElements, rootElements],
  );

/**
 * Check the element or any of its parents are deactivated.
 */
export const useIsDeactivated = (element: OneOfElements): boolean => {
  const path = useElementPath(element);
  return [...path, element].some((e) => isDeactivated(e));
};
