import React, { useCallback, useMemo } from 'react';
import { CropSquareSharp } from '@mui/icons-material';
import {
  MenuItem,
  Divider,
  Box,
  Typography,
  ListItemIcon,
} from '@mui/material';
import {
  isProductCategory,
  isInstalltionCategory,
  isSystemCategory,
} from '../../../../../../shared/helpers/element_category_helpers';
import {
  ElementCategoryID,
  IElementCategory,
} from '../../../../../../shared/models/element_categories.interface';
import { getAvailableCategories } from '../../../../../../shared/templates/categories';
import { IElement } from '../../../../../../shared/models/project.interface';
import { useIsRootElement } from '../../../../hooks/useElement';
import { useSelectElementCategory } from '../../../../hooks/element-category.hook';
import { closeKebabMenu } from '../../../kebab-menu/kebab-menu.hooks';

interface ICategoryGroup {
  name?: string;
  categories: IElementCategory[];
}

export const useElementCategoryItems = (
  variant: 'click' | 'change',
  element: IElement,
  filterCategories: Readonly<ElementCategoryID[]> = [],
): React.ReactNode[] => {
  const isRootElement = useIsRootElement();
  const selectElementCategory = useSelectElementCategory(element);

  const categories = useMemo(
    () =>
      getAvailableCategories(element.category_id).filter(
        (category) => !filterCategories.includes(category.id),
      ),
    [element.category_id, filterCategories],
  );

  const groups = useCategoryGroups(categories);

  const handleItemClick = useCallback(
    ({
      currentTarget: {
        dataset: { categoryId },
      },
    }: React.MouseEvent<HTMLElement>) => {
      if (variant === 'click') {
        selectElementCategory(categoryId as ElementCategoryID);
        closeKebabMenu();
      }
    },
    [selectElementCategory, variant],
  );

  return useMemo(() => {
    return groups.flatMap((group, index) => {
      return [
        !!group.name && (
          <Box key={'group-label-' + index} pl={2} pt={2} pb={2}>
            <Typography variant="menuTitle">{group.name}</Typography>
          </Box>
        ),
        ...group.categories.map((category) => (
          <MenuItem
            key={category.id}
            value={category.id}
            data-category-id={category.id}
            onClick={handleItemClick}
            disabled={
              category.id === ElementCategoryID.OtherProduct &&
              isRootElement(element)
            }
          >
            <Box
              display="flex"
              paddingLeft={isSystemCategory(category.id) ? 3 : 0}
            >
              {!isSystemCategory(category.id) && (
                <ListItemIcon
                  sx={{
                    minWidth: '25px !important',
                    alignItems: 'center',
                  }}
                >
                  <CropSquareSharp
                    fontSize="small"
                    sx={{
                      visibility: isSystemCategory(category.id)
                        ? 'hidden'
                        : 'visible',
                      transform: 'rotate(45deg) scale(0.5)',
                    }}
                  />
                </ListItemIcon>
              )}
              {category.name}
            </Box>
          </MenuItem>
        )),
        index < groups.length - 1 && (
          <Divider
            key={'divider-systems-products-' + index}
            variant="fullWidth"
          />
        ),
      ];
    });
  }, [element, groups, handleItemClick, isRootElement]);
};

/**
 * Groups the categories into system, product, service and other.
 * @param categories
 * @returns
 */
const useCategoryGroups = (
  categories: IElementCategory[],
): ICategoryGroup[] => {
  const systemCategories: ICategoryGroup = {
    name: 'System',
    categories: categories.filter((category) => isSystemCategory(category.id)),
  };

  const productCategories: ICategoryGroup = {
    name: 'Product',
    categories: categories.filter(
      ({ id }) =>
        isProductCategory(id) &&
        // Exclude energy and other product categories to put them in the other group.
        ![ElementCategoryID.Energy, ElementCategoryID.OtherProduct].includes(
          id,
        ),
    ),
  };

  const serviceCategories: ICategoryGroup = {
    name: 'Installation',
    categories: categories.filter((category) =>
      isInstalltionCategory(category.id),
    ),
  };

  const groupedCategories = [
    systemCategories,
    productCategories,
    serviceCategories,
  ];

  const otherCategories: ICategoryGroup = {
    name: '',
    categories: categories.filter(
      (cat) =>
        !groupedCategories.some((group) => group.categories.includes(cat)),
    ),
  };

  return [...groupedCategories, otherCategories].filter(
    (group) => group.categories.length > 0,
  );
};
