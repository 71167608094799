import React, { useCallback, useMemo } from 'react';
import ProjectKebabMenu from './ProjectKebabMenu';
import { enqueueSnackbar } from 'notistack';
import { getProject, useUpdateProject } from '../../../store/project';
import { Download, Refresh } from '@mui/icons-material';
import amplitudeLog from '../../../amplitude';
import { useForceUpdateProductsInProjectCallback } from '../../../hooks/useProducts';
import { projectActionMenuIconStyles } from '../../../style/constants';
import { IMenuItemData } from '../../kebab-menu/KebabMenu';
import { useIsReadonly, useUserIsOwner } from '../../../hooks/user.hook';
import { useBooleanState } from '../../../hooks/hooks';
import { useImportElements } from '../../../hooks/import.hook';
import ImportElementsDialog from '../../ImportElements/ImportElementsDialog';

const ProjectHeaderKebabMenu: React.FC = () => {
  const project = getProject();
  const readonly = useIsReadonly();
  const isOwner = useUserIsOwner(project.id);

  const importElements = useImportElements();
  const updateProject = useUpdateProject();
  const updateProductsInProjectCallback =
    useForceUpdateProductsInProjectCallback();

  const [importDialogOpen, openImportDialog, closeImportDialog] =
    useBooleanState(false);

  const handleImport = useCallback(
    async (files: File[]): Promise<void> => {
      await importElements(files);
      closeImportDialog();
    },
    [importElements, closeImportDialog],
  );

  const handleUpdateProducts = useCallback(async () => {
    const updatedProject = updateProductsInProjectCallback(project);

    if (updatedProject !== project) {
      await updateProject(updatedProject, {
        successMessage: `Products updated in "${project.name}"`,
        errorMessage: `Failed to update products in "${project.name}"`,
        logError: true,
      });
      amplitudeLog('Project Products Update', {
        ProjectId: project.id,
      });
    } else {
      enqueueSnackbar('Products are already up to date', {
        variant: 'info',
      });
    }
  }, [project, updateProductsInProjectCallback, updateProject]);

  const extraItems = useMemo<IMenuItemData[]>(() => {
    const disabled = (!isOwner && !!project.locked) || !!project.archived;

    return [
      {
        id: 'import',
        icon: <Download {...projectActionMenuIconStyles} />,
        defaultMessage: 'Import elements',
        onClick: () => openImportDialog(),
        disabled: readonly,
      },
      {
        id: 'update_products',
        defaultMessage: 'Update products',
        icon: <Refresh {...projectActionMenuIconStyles} />,
        disabled,
        tooltipTitle: disabled
          ? 'Products cannot be updated in readonly projects'
          : '',
        onClick: handleUpdateProducts,
      },
    ];
  }, [
    handleUpdateProducts,
    isOwner,
    openImportDialog,
    project.archived,
    project.locked,
    readonly,
  ]);

  return (
    !!project.id && (
      <>
        <ProjectKebabMenu
          projectId={project.id}
          projectName={project.name}
          locked={!!project.locked}
          archived={!!project.archived}
          extraItems={extraItems}
          insertItemsLast
          verticalKebabIcon
        />
        <ImportElementsDialog
          title="Import elements"
          content="Drag a CSV, Excel or Bidcon file here to import its elements"
          open={importDialogOpen && !readonly}
          onImport={handleImport}
          onClose={closeImportDialog}
        />
      </>
    )
  );
};

export default ProjectHeaderKebabMenu;
