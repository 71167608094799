import React, { FC, useCallback } from 'react';
import { MoreHoriz, MoreVert } from '@mui/icons-material';
import {
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Tooltip,
  Checkbox,
  SxProps,
  Theme,
  BoxProps,
  SvgIconProps,
} from '@mui/material';

import { FormattedMessage } from 'react-intl';
import { mouseEventHandler } from '../../../../shared/helpers/event_helpers';
import {
  useKebabMenuPosition,
  openKebabMenu,
  closeKebabMenu,
  kebabMenuMouseHandler,
} from './kebab-menu.hooks';
import { useMouseEventCallback } from '../../hooks/events.hook';
import { useObjectMemo } from '../../hooks/hooks';

export interface IMenuItemData {
  id: string;
  icon?: JSX.Element;
  defaultMessage: string;
  disabled?: boolean;
  tooltipTitle?: string;
  /**
   * Pass boolean to show checkbox (undefined will not show checkbox)
   */
  checked?: boolean;
  checkboxColor?: string;
  onClick: (...args: any[]) => Promise<any> | void | undefined;
}

interface IKebabMenuInput extends Omit<BoxProps, 'id'> {
  id: string | number;
  /**
   * If no items are provided, the kebab menu will be disabled
   */
  items: IMenuItemData[];
  hidden?: boolean;
  iconProps?: Partial<IKebabIconInput>;
  onClose?: () => void;
  onOpen?: () => void;
}

export const KebabMenu: FC<IKebabMenuInput> = ({
  id,
  items,
  hidden,
  iconProps,
  onClose,
  onOpen,
  ...boxProps
}) => {
  if (!id) {
    throw new Error('KebabMenu: key is required');
  }
  const position = useKebabMenuPosition(id);
  const isOpen = !!position;
  const open: IKebabIconInput['onClick'] = useMouseEventCallback(
    (e) => {
      const anchorPosition = kebabMenuMouseHandler(e);
      if (anchorPosition) {
        openKebabMenu({ anchorPosition, id });
      }
      onOpen?.();
    },
    { stopPropagation: true },
  );

  const handleClose = useCallback(() => {
    onClose?.();
    closeKebabMenu();
  }, [onClose]);

  const sx: SxProps<Theme> = useObjectMemo({
    opacity: hidden ? 0 : 1,
    transition: 'opacity 0.3s',
  });

  return (
    <Box
      width={20}
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={sx}
      m="6px 8px"
      {...boxProps}
    >
      <>
        <KebabIcon
          disabled={items.length === 0}
          onClick={open}
          {...iconProps}
        />
        <Menu
          open={isOpen}
          anchorReference="anchorPosition"
          anchorPosition={position}
          onClose={handleClose}
          closeAfterTransition={false}
        >
          {items.map(
            ({
              onClick,
              icon,
              id,
              defaultMessage,
              disabled,
              checked,
              checkboxColor,
              tooltipTitle,
            }) => (
              <Tooltip key={id} title={tooltipTitle} disableInteractive>
                <span>
                  <MenuItem
                    onClick={mouseEventHandler(async () => {
                      await onClick();
                      closeKebabMenu();
                    })}
                    disabled={disabled}
                  >
                    {typeof checked === 'boolean' && (
                      <ListItemIcon>
                        <Checkbox
                          sx={{
                            ...ICON_PADDING,
                            '& .MuiSvgIcon-root': checkboxColor
                              ? {
                                  fill: checkboxColor,
                                }
                              : {},
                          }}
                          size="small"
                          checked={checked}
                        />
                      </ListItemIcon>
                    )}
                    {icon && (
                      <ListItemIcon sx={ICON_PADDING}>{icon}</ListItemIcon>
                    )}
                    <ListItemText>
                      <FormattedMessage
                        id={id}
                        defaultMessage={defaultMessage}
                      />
                    </ListItemText>
                  </MenuItem>
                </span>
              </Tooltip>
            ),
          )}
        </Menu>
      </>
    </Box>
  );
};

const ICON_PADDING = {
  padding: 1,
};

export interface IKebabIconInput {
  tooltipTitle?: string;
  disabled?: boolean;
  svgProps?: SvgIconProps;
  verticalIcon?: boolean;
  onClick: React.MouseEventHandler<HTMLElement>;
}

const KebabIcon: FC<IKebabIconInput> = ({
  tooltipTitle,
  disabled,
  svgProps,
  verticalIcon,
  onClick,
}) => (
  <Tooltip title={tooltipTitle}>
    <IconButton
      size="small"
      color={disabled ? 'secondary' : 'default'}
      onClick={onClick}
      component="span"
      disabled={disabled}
    >
      {verticalIcon ? (
        <MoreVert {...svgProps} />
      ) : (
        <MoreHoriz color="secondary" {...svgProps} />
      )}
    </IconButton>
  </Tooltip>
);
