import { useCallback } from 'react';
import { DropType } from '../components/drag-and-drop/Droppable';
import {
  isElementWithGeneratedChildren,
  isGeneratedProductElement,
} from '../../../shared/helpers/element_helpers';
import {
  addElements,
  getVersionById,
  removeElements,
} from '../../../shared/helpers/project_helpers';
import {
  getChildElements,
  getElementById,
  getParentElement,
  isBuildingVersionElement,
  isParentElement,
  isProductElement,
} from '../../../shared/helpers/recursive_element_helpers';
import {
  IBuildingVersion,
  OneOfChildElements,
  OneOfParentElements,
  Project,
} from '../../../shared/models/project.interface';
import { getProject, useUpdateProject } from '../store/project';
import { getSelectedVersion } from '../store/ui';
import { isDefined } from '../../../shared/helpers/array_helpers';
import { IInsertSortPlacement } from '../../../shared/models/sort.interface';
import {
  getElementVersionId,
  getElementVersionsById,
} from '../../../shared/helpers/element-version.helpers';

interface IUseMoveElements {
  moveElementsTo: (
    moveIntoId: string,
    moveType: DropType,
    ...elementIds: string[]
  ) => Promise<Project>;
  isMoveElementsAllowed: (
    moveToId: string | undefined,
    moveType?: DropType,
    ...elementIds: string[]
  ) => boolean;
}

export const useMoveElements = (): IUseMoveElements => {
  const updateProject = useUpdateProject();

  const isMoveElementsAllowed = useCallback(
    (
      moveToId: string | undefined,
      moveType?: DropType,
      ...elementIds: string[]
    ) => {
      const selectedVersion = getSelectedVersion();

      if (!selectedVersion || elementIds.length === 0) {
        return false;
      }

      const moveIntoElement = getMoveIntoElement(moveToId, moveType);

      // Can't move element before or after a version
      if (
        moveType !== 'inside' &&
        isBuildingVersionElement(moveIntoElement) &&
        moveIntoElement.id === moveToId
      ) {
        return false;
      }

      // Can't move element to a product element
      if (!moveIntoElement || !isParentElement(moveIntoElement)) {
        return false;
      }

      // Can't move element to an element with generated children
      if (isElementWithGeneratedChildren(moveIntoElement)) {
        return false;
      }

      const every = elementIds.every((elementId) => {
        const element = getElementById(selectedVersion, elementId);
        const children = getChildElements(moveIntoElement);

        // Can't move element into itself
        if (!element || element.id === moveIntoElement.id) {
          return false;
        }

        // Can't move generated elements
        if (isGeneratedProductElement(element)) {
          return false;
        }

        // Can't move product elements into a building version
        if (
          isProductElement(element) &&
          isBuildingVersionElement(moveIntoElement)
        ) {
          return false;
        }

        // Can't move element below or above itself
        if (moveType !== 'inside' && moveToId === elementId) {
          return false;
        }

        // Can't move element to the parent since it's already there
        if (
          moveType === 'inside' &&
          children.some((child) => child.id === element.id)
        ) {
          return false;
        }

        // Can't move element to a child element
        return !getElementById(element, moveIntoElement.id);
      });
      return every;
    },
    [],
  );

  const moveElementsTo = useCallback(
    async (moveToId: string, moveType?: DropType, ...elementIds: string[]) => {
      if (!isMoveElementsAllowed(moveToId, moveType, ...elementIds)) {
        throw new Error('Move not allowed');
      }

      const project = getProject();
      const selectedVersion = getSelectedVersion();

      if (!selectedVersion) {
        throw new Error('selectedVersion not found');
      }

      const projectWithMovedElements = moveElements(
        project,
        selectedVersion,
        moveToId,
        moveType,
        ...elementIds,
      );

      // If all elements are already in the right "folder" we don't need to update the project
      if (projectWithMovedElements !== project) {
        return await updateProject(projectWithMovedElements);
      }

      return projectWithMovedElements;
    },
    [isMoveElementsAllowed, updateProject],
  );

  return { moveElementsTo, isMoveElementsAllowed };
};

const getMoveIntoElement = (
  moveToId?: string,
  moveType: DropType = 'inside',
): OneOfParentElements | undefined => {
  const selectedVersion = getSelectedVersion();

  if (!selectedVersion || typeof moveToId !== 'string') {
    throw new Error('selected version not found');
  }

  const moveToElement = getElementById(selectedVersion, moveToId);

  // After or behind will move the element into the parent
  const moveIntoElement =
    moveType === 'inside'
      ? moveToElement
      : getParentElement(selectedVersion, moveToElement);

  return isParentElement(moveIntoElement) ? moveIntoElement : undefined;
};

const moveElements = (
  project: Project,
  version: IBuildingVersion,
  moveToId: string,
  moveType: DropType = 'inside',
  ...elementIds: string[]
): Project => {
  if (elementIds.length === 0) {
    return project;
  }

  // Get the parent element to move the elements into
  const parent = getMoveIntoElement(moveToId, moveType);

  if (!parent || !isParentElement(parent)) {
    throw new Error('Could not find parent element to move to');
  }

  // Store elements to move
  const elements = elementIds
    .flatMap((id) => {
      const el = getElementById(version, id);
      const versionId = getElementVersionId(el);
      if (!versionId) {
        return el;
      }
      return getElementVersionsById(parent, versionId);
    })
    .filter(isDefined);

  const sibling = getElementById(project, moveToId) as OneOfChildElements;

  let placement: IInsertSortPlacement = 'last';
  if (moveType === 'above') placement = 'before';
  if (moveType === 'below') placement = 'after';

  // Remove elements from old positions and keep the updated project & version
  project = removeElements(project, version, ...elementIds);
  version = getVersionById(project, version.id);

  return addElements(project, parent, { placement, sibling }, ...elements);
};
