import React, { useCallback, useEffect, useRef } from 'react';
import {
  AppBar as MuiAppBar,
  Box,
  Button,
  Link,
  Theme,
  Toolbar,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import AddIcon from '@mui/icons-material/Add';
import Logo from '../nodon-logo.png';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import ProfileMenu from '../components/ProfileMenu';
import { IProjectInfo } from '../../../shared/models/project.interface';
import OrganizationsMenu from './OrganizationsMenu';
import amplitudeLog from '../amplitude';
import { useOrganizationGFAQuotaIsExceeded } from '../store/organization';
import { useUIState } from '../store/ui';
import GFAQuotaExceededAlert from './GFAQuotaExceededAlert';
import { useIsReadonly, useUser } from '../hooks/user.hook';
import ShareProjectButton from './ShareProjectButton';
import ProjectHeaderKebabMenu from './ElementList/KebabMenus/ProjectHeaderKebabMenu';

const useStyles = makeStyles()(({ zIndex, spacing, palette }: Theme) => ({
  appBar: {
    zIndex: zIndex.drawer + 1,
  },

  logo: {
    width: '48px',
    height: '48px',
  },

  toolbar: {
    flexDirection: 'column',
    justifyContent: 'center',
  },

  button: {
    alignItems: 'center',
    padding: spacing(1),
    fontSize: '0.5rem',
    ['@media (min-width:935px)']: {
      fontSize: '0.625rem',
    },
    ['@media (min-width:1088px)']: {
      padding: spacing(2),
      fontSize: '0.75rem',
    },
  },

  buttonIcon: {
    marginRight: 0,
    fontSize: '0.5rem',
    ['@media (min-width:935px)']: {
      fontSize: '0.625rem',
    },
    ['@media (min-width:1088px)']: {
      marginRight: spacing(1),
      fontSize: '0.75rem',
    },
  },

  buttonText: {
    marginBottom: -1,
  },

  settingsButton: {
    margin: spacing(0, 3),
  },

  buttonHide: {
    display: 'none',
  },

  projectMenuButton: {
    display: 'inline-block',
    // maxWidth: '39%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    uppercase: 'default',
  },

  sharing: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: spacing(4),
    paddingRight: spacing(4),
    cursor: 'pointer',
  },

  sharingHeader: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    fontSize: '0.75rem',
    '> svg': {
      marginleft: spacing(2),
      fontSize: '0.75rem',
    },
  },

  shareButton: {
    width: '100%',
  },

  sharingText: {
    display: 'inline-block',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '0.75rem',
    '&:hover': {
      color: palette.secondary.main,
    },
  },

  sharingDeleteIcon: {
    '&:hover': {
      color: palette.secondary.main,
    },
  },

  shareButtonIcon: {
    marginRight: spacing(1),
    '& svg': {
      fontSize: '0.5rem !important',
    },
    ['@media (min-width:935px)']: {
      marginTop: -2,
      '& svg': {
        fontSize: '0.75rem !important',
      },
    },
    ['@media (min-width:1088px)']: {
      marginTop: -3,
      marginRight: spacing(1),
      '& svg': {
        fontSize: '1rem !important',
      },
    },
  },
}));

interface AppBarProps {
  project?: IProjectInfo;
}

const AppBar: React.FC<AppBarProps> = ({ project }) => {
  const { showProjectSelector, setShowProjectSelector, setAppHeaderOffset } =
    useUIState(
      'showProjectSelector',
      'setShowProjectSelector',
      'setAppHeaderOffset',
    );

  const navigate = useNavigate();

  const { classes } = useStyles();
  const user = useUser();
  const organizationGFAQuotaIsExceeded = useOrganizationGFAQuotaIsExceeded();
  const readonly = useIsReadonly();

  const appBarRef = useRef<HTMLElement>(null);

  const handleNewProjectClick = useCallback(() => {
    navigate('/projects/new');
    amplitudeLog('New Project clicked (in app bar)');
  }, [navigate]);

  const handleProjectMenuClick = useCallback(
    () => setShowProjectSelector(!showProjectSelector),
    [showProjectSelector, setShowProjectSelector],
  );

  useEffect(() => {
    setTimeout(() => {
      if (appBarRef.current && project?.gfa) {
        setAppHeaderOffset(appBarRef.current.clientHeight);
      }
    }, 500);
  }, [project, setAppHeaderOffset]);

  return (
    <MuiAppBar
      position="fixed"
      className={classes.appBar}
      color="inherit"
      sx={APPBAR_SX}
      ref={appBarRef}
    >
      <Toolbar className={classes.toolbar} disableGutters>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          p="0 12px"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center" mr={5}>
              <Link component={RouterLink} to="/" underline="none">
                <Box display="flex" alignItems="center" mr={1}>
                  <img alt="nodon logo" src={Logo} className={classes.logo} />
                </Box>
              </Link>

              {project?.name && (
                <Button onClick={handleProjectMenuClick}>{project.name}</Button>
              )}
            </Box>

            {!showProjectSelector && <ProjectHeaderKebabMenu />}

            {!readonly && <ShareProjectButton />}
          </Box>

          <Box display="flex" alignItems="center" justifyContent="flex-end">
            {!showProjectSelector && user && (
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                startIcon={<AddIcon />}
                disabled={showProjectSelector}
                classes={{ startIcon: classes.buttonIcon }}
                sx={{ padding: '5px 10px', margin: '0 12px' }}
                onClick={handleNewProjectClick}
              >
                <span className={classes.buttonText}>
                  <FormattedMessage
                    id="projects_panel.new_project"
                    defaultMessage="New project"
                  />
                </span>
              </Button>
            )}

            <Box display="flex" alignItems="center">
              {!!user?.organizations.length && (
                <OrganizationsMenu organizations={user.organizations} />
              )}

              <ProfileMenu />
            </Box>
          </Box>
        </Box>
        {organizationGFAQuotaIsExceeded && <GFAQuotaExceededAlert />}
      </Toolbar>
    </MuiAppBar>
  );
};

const APPBAR_SX = { displayPrint: 'none' } as const;

export default AppBar;
