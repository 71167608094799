import React, { useCallback, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { OneOfElementListElements } from '../../../../shared/models/project.interface';
import { DEFAULT_BAR_CHART_CO2_COLOR } from '../../../../shared/constants';
import SimpleBarChart from './SimpleBarChart';
import {
  useElementResultsOfAllProposals,
  useGetProposalColor,
  useProposalsUsingElement,
} from '../../hooks/proposals.hook';
import { formatThousands } from '../../../../shared/helpers/math_helpers';
import {
  ELEMENT_CO2_BAR_HEIGHT,
  ELEMENT_COST_BAR_HEIGHT,
} from '../ElementList/list.constants';
import {
  useElementMaxResults,
  useElementResults,
} from '../../hooks/results.hook';
import { IProposal } from '../../../../shared/models/proposals.interface';

interface IElementBarChartsProps {
  element: OneOfElementListElements;
  color?: string;
  width?: number | string;
  dimmed?: boolean;
}

const ElementBarCharts: React.FC<IElementBarChartsProps> = ({
  element,
  width = '100%',
  dimmed,
  color = DEFAULT_BAR_CHART_CO2_COLOR,
}) => {
  const { classes, cx } = useStyles();

  const proposalResults = useElementResultsOfAllProposals(element);

  const { co2e_total: co2, 'sek_A1-A3': cost = 0 } = useElementResults(element);

  const proposals = useProposalsUsingElement(element);
  const getProposalColor = useGetProposalColor();

  const getTooltipLabel = useCallback(
    (co2: number = 0, cost: number = 0, name?: string) =>
      `${name ? name + ': ' : 0}${formatThousands(co2)} kgCO2e, ${formatThousands(cost)} SEK`,
    [],
  );

  const getProposalTooltipLabel = useCallback(
    (proposal: IProposal) => {
      const proposalResult = proposalResults[proposal.id];
      return getTooltipLabel(
        proposalResult?.co2e_total,
        proposalResult?.['sek_A1-A3'],
        proposal.name,
      );
    },
    [getTooltipLabel, proposalResults],
  );

  const maxCO2 = useElementMaxResults('co2e_total').co2e_total;
  const maxCost = useElementMaxResults('sek_A1-A3')['sek_A1-A3'];

  const firstProposals = useMemo(
    () => (proposals.length > 5 ? proposals.slice(0, 5) : proposals),
    [proposals],
  );

  if (!maxCO2 && !maxCost) {
    return <Typography variant="caption">0 kgCO2e, 0 SEK</Typography>;
  }

  return (
    // <Tooltip title={tooltipLabel}>
    <Box
      width={width}
      className={cx(classes.container, dimmed && classes.dimmed)}
    >
      {/* One chart per proposal refering to this element */}
      {firstProposals.map((proposal) => (
        <Box key={'wrapper-' + proposal.id} className={cx(classes.chart)}>
          <SimpleBarChart
            key={proposal.id}
            tooltip={getProposalTooltipLabel(proposal)}
            color={getProposalColor(proposal.id)}
            value={proposalResults[proposal.id]?.co2e_total}
            maxValue={maxCO2}
            height={ELEMENT_CO2_BAR_HEIGHT}
          />
        </Box>
      ))}

      {/* No proposals, show one for all */}
      {proposals.length === 0 && (
        <Box className={cx(classes.chart)}>
          <SimpleBarChart
            tooltip={getTooltipLabel(co2, cost)}
            value={co2}
            maxValue={maxCO2}
            color={color}
            height={ELEMENT_CO2_BAR_HEIGHT}
          />
        </Box>
      )}

      {/* Cost (same for all) */}
      <Box className={cx(classes.chart)}>
        <SimpleBarChart
          tooltip={formatThousands(cost) + ' SEK'}
          height={ELEMENT_COST_BAR_HEIGHT}
          value={cost}
          maxValue={maxCost}
          color="black"
        />
      </Box>
    </Box>
    // </Tooltip>
  );
};

const useStyles = makeStyles()(() => ({
  container: {
    position: 'relative',
    display: 'block',
    height: 'auto',
  },
  dimmed: {
    opacity: 0.5,
  },
  chart: {
    width: '100%',
    '&:not(:last-child)': {
      marginBottom: '2px',
    },
  },
}));

export default ElementBarCharts;
