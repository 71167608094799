import React, {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { Box, BoxProps, SelectProps, SxProps, TextField } from '@mui/material';
import { ElementCategoryID } from '../../../../../../shared/models/element_categories.interface';
import ElementProperties from '../Property/ElementProperties';
import { isMainCategory } from '../../../../../../shared/templates/categories';
import { useIsReadonly } from '../../../../hooks/user.hook';
import { isElementCategoryID } from '../../../../../../shared/helpers/element_category_helpers';
import { useElementPropertiesUtils } from '../../../../hooks/element-properties.hook';
import { IElement } from '../../../../../../shared/models/project.interface';
import {
  getLastSelectedElementCategoryId,
  setLastSelectedElementCategoryId,
  useSelectElementCategory,
} from '../../../../hooks/element-category.hook';
import { useElementCategoryItems } from './ElementCategoryItems';
import { useUIState } from '../../../../store/ui';
import { useBooleanState } from '../../../../hooks/hooks';

interface ElementCategoryMenuProps extends BoxProps {
  element: IElement;
  filterCategories?: Readonly<ElementCategoryID[]>;
  filterProperties?: string[];
}

const ElementCategoryMenu: React.FC<ElementCategoryMenuProps> = ({
  element,
  filterCategories,
  filterProperties,
  ...boxProps
}) => {
  const { addedElementId, setAddedElementId } = useUIState(
    'addedElementId',
    'setAddedElementId',
  );

  const selectElementCategory = useSelectElementCategory(element);
  const { categoryProperties } = useElementPropertiesUtils(element);

  const { category_id } = element;
  const isMainEC = isMainCategory(category_id);
  const readonly = useIsReadonly();

  const defaultCategoryId =
    getLastSelectedElementCategoryId() ?? ElementCategoryID.None;

  const items = useElementCategoryItems('change', element, filterCategories);

  const [open, showMenu, hideMenu] = useBooleanState(false);

  const selectProps = useMemo<Partial<SelectProps>>(
    () => ({
      inputProps: { sx: { display: 'flex' } },
      open,
      onClose: hideMenu,
      onOpen: showMenu,
    }),
    [hideMenu, open, showMenu],
  );

  const handleChange = useCallback<
    ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  >(
    ({ target: { value } }) => {
      selectElementCategory(value as ElementCategoryID);
      setLastSelectedElementCategoryId(value as ElementCategoryID);
    },
    [selectElementCategory],
  );

  useEffect(() => {
    if (element.id === addedElementId) {
      showMenu();
      setAddedElementId(undefined);
    }
  }, [
    addedElementId,
    defaultCategoryId,
    element.category_id,
    element.id,
    selectElementCategory,
    setAddedElementId,
    showMenu,
  ]);

  return (
    <Box {...boxProps}>
      {!isMainEC && (
        <TextField
          select
          fullWidth
          size="small"
          disabled={readonly}
          SelectProps={selectProps}
          sx={boxStyles}
          value={
            (isElementCategoryID(category_id)
              ? category_id
              : defaultCategoryId) ?? 'None'
          }
          onChange={handleChange}
        >
          {...items}
        </TextField>
      )}
      {category_id && (
        <ElementProperties
          element={element}
          properties={categoryProperties}
          filterProperties={filterProperties}
        />
      )}
    </Box>
  );
};

const boxStyles: SxProps = { paddingBottom: 1 } as const;

export default ElementCategoryMenu;
