import { ProductRecord } from '../models/product.interface';
import {
  IBuildingVersion,
  OneOfElementListElements,
  Project,
} from '../models/project.interface';
import { Recipe } from '../models/recipe.interface';
import {
  enrichElementStructure,
  getModifiedElements,
  getProjectMeta,
  getVersionById,
  updateBuildingVersionProperties,
  updateElements,
} from './project_helpers';
import { updateVersionExpressionValues } from './expression_solving_helpers';
import { getExpressionVariablesRecord } from './expression_variables_helpers';
import { getId, omit } from './object_helpers';
import {
  addMissingProductsToVersion,
  updateVersionProductRecord,
} from './product_helpers';
import {
  getAllBuildingVersions,
  getBuildingVersionById,
  findElementAndParent,
  flattenElements,
} from './recursive_element_helpers';
import {
  generateProposalResultsRecord,
  generateVersionResultsRecord,
} from './results.helpers';
import { calculatedUnits } from '../models/unit.interface';
import { isEqual } from 'lodash';
import { getProposalsInVersion, updateProposals } from './proposal.helpers';
import { PickRequired } from '../models/type_helpers.interface';
import { IProposal } from '../models/proposals.interface';

interface IEnrichProjectData {
  prevProject: Project;
  productRecord: ProductRecord;
  recipes: Recipe[];
}

/**
 * Make sure products and expressions are updated.
 * Made to not trigger any redundant rerenders.
 * @param project
 * @returns
 */
export const enrichProject = (
  project: Project,
  { prevProject, productRecord: products, recipes }: IEnrichProjectData,
): Project => {
  const isMetaModified =
    getProjectMeta(prevProject) !== getProjectMeta(project);

  if (project === prevProject) {
    return project;
  }

  //  project = updateProjectExpressionValues(project, prevProject);

  // Since project will change every loop we use ids to not overwrite changes
  getAllBuildingVersions(project)
    .map(getId)
    .forEach((versionId) => {
      const previousVersion = getBuildingVersionById(prevProject, versionId);

      // Get latest version since it will change every overwrite of project
      const getVersion = () => getVersionById(project, versionId);

      // For performance reasons we only update changed versions or if meta has changed (since it affects all versions)
      if (previousVersion !== getVersion() || isMetaModified) {
        // To make getProductDensity() work we need to make sure version.products is up-to-date with latest products
        if (products) {
          project = updateBuildingVersionProperties(
            project,
            addMissingProductsToVersion(getVersion(), products, recipes),
          );
        }

        project = enrichModifiedElements(
          project,
          getVersion(),
          previousVersion,
          products,
        );

        // Make sure product records are updated (after enrich since it might add elements)
        if (products) {
          project = updateBuildingVersionProperties(
            project,
            updateVersionProductRecord(getVersion(), products, recipes),
          );
        }

        // Make sure all expressions values are updated
        project = updateVersionExpressionValues(project, getVersion());

        // Store a record of all element results on proposals
        project = applyProposalsResultRecords(project, getVersion());

        // Store co2e and other results on every element
        project = applyElementResults(project, getVersion());
      }
    });

  return project;
};

const applyElementResults = (
  project: Project,
  version: IBuildingVersion,
): Project => {
  const factors = generateVersionResultsRecord(version);
  const elements = flattenElements(version);
  const updates: Pick<OneOfElementListElements, 'id' | 'results'>[] = [];

  for (const element of elements) {
    const results = omit(factors[element.id], ...calculatedUnits);
    if (results && !isEqual(element.results, results)) {
      updates.push({ id: element.id, results });
    }
  }
  return updateElements(project, ...updates);
};

/**
 * Store records with results on proposals
 * @param project
 * @param version
 * @returns
 */
const applyProposalsResultRecords = (
  project: Project,
  version: IBuildingVersion,
): Project => {
  const proposals = getProposalsInVersion(version);

  if (!proposals.length) {
    return project;
  }

  const updates: PickRequired<IProposal, 'resultsRecord' | 'id'>[] =
    proposals.map((proposal) => ({
      id: proposal.id,
      resultsRecord: generateProposalResultsRecord(version, proposal),
    }));

  return updateElements(project, updateProposals(version, ...updates));
};

/**
 * Enrich elements that have been modified (including their children)
 * to make sure properties and quantities are up-to-date
 * @param version
 * @returns
 */
const enrichModifiedElements = (
  project: Project,
  version: IBuildingVersion,
  previousVersion: IBuildingVersion | undefined,
  productLookup: ProductRecord,
): Project => {
  const versionId = version.id;

  // Get all available variables for us to resolve fallbackExpressions
  const variablesRecord = getExpressionVariablesRecord(project, version);

  // Get the id of the elements that have changed in version
  const changedIds = getModifiedElements(version, previousVersion).map(getId);

  for (const id of changedIds) {
    // Fetch latest updated element (project & version changed every loop)
    const { element, path } = findElementAndParent(
      getVersionById(project, versionId),
      id,
    );

    if (element) {
      const enrichedElement = enrichElementStructure(
        element,
        path,
        variablesRecord,
        productLookup,
      );

      // if changed append to project
      if (enrichedElement !== element) {
        project = updateElements(project, enrichedElement);
      }
    }
  }

  return project;
};
