import React, { useMemo } from 'react';
import { IElement } from '../../../../../shared/models/project.interface';

import { Box, Tooltip, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import RecipeActionMenu from './Recipe/RecipeActionMenu';
import ElementPropertiesAddButton from './Property/ElementPropertiesAddButton';
import RecipeMenu from './Recipe/RecipeMenu';
import ElementProperties from './Property/ElementProperties';
import { useElementPropertiesUtils } from '../../../hooks/element-properties.hook';
import { useRecipe } from '../../../hooks/useRecipes';
import { useIsReadonly } from '../../../hooks/user.hook';

export interface ElementRecipeProps {
  element: IElement;
  isSelectViewOnly?: boolean;
  tooltipTitle?: string;
}

const ElementRecipe: React.FC<ElementRecipeProps> = ({
  element,
  isSelectViewOnly,
  tooltipTitle,
}) => {
  const readonly = useIsReadonly();
  const { category_id } = element;
  const recipe = useRecipe(element);
  const { addProperty, customProperties, recipeProperties } =
    useElementPropertiesUtils(element);

  const elementProperties = useMemo(
    () => [...customProperties, ...recipeProperties],
    [customProperties, recipeProperties],
  );

  // Simpler recipe view for element list
  if (isSelectViewOnly && category_id) {
    return (
      <Tooltip title={tooltipTitle}>
        <div>
          <RecipeMenu
            element={element}
            isSelectViewOnly={isSelectViewOnly}
            hideLabel={true}
          />
        </div>
      </Tooltip>
    );
  }

  // For right panel
  if (!isSelectViewOnly) {
    return (
      <Box pt={2} pb={4}>
        {category_id && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="subtitle2">
                <FormattedMessage
                  id="element_recipe.recipe"
                  defaultMessage="Recipe"
                />
              </Typography>
              <RecipeActionMenu element={element} recipe={recipe} />
            </Box>
            <RecipeMenu element={element} />
          </>
        )}
        <Box>
          {recipe?.description && (
            <Box pt={2}>
              <Tooltip title={recipe?.description} enterDelay={1500}>
                <Typography
                  variant="body2"
                  component="div"
                  style={{
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    wordBreak: 'break-word',
                    backgroundColor: 'white',
                    borderRadius: '5px',
                    color: 'rgba(0,0,0,0.4)',
                    fontFamily: 'Arial, sans-serif',
                    fontSize: '14px',
                    lineHeight: '1.5',
                    fontWeight: 400,
                    padding: '5px',
                    marginBottom: '3px',
                  }}
                >
                  {recipe?.description}
                </Typography>
              </Tooltip>
            </Box>
          )}
        </Box>
        <Box
          pt={4}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="subtitle2">
            <FormattedMessage
              id="element_recipe.properties"
              defaultMessage="Properties"
            />
          </Typography>

          {!readonly && (
            <ElementPropertiesAddButton onAddProperty={addProperty} />
          )}
        </Box>

        <ElementProperties element={element} properties={elementProperties} />
      </Box>
    );
  }

  return <></>;
};

export default ElementRecipe;
