import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import deepmerge from 'deepmerge';
import {
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Add, Close } from '@mui/icons-material';
import Fuse from 'fuse.js';
import {
  IAnyBoolean,
  Product,
  ProductID,
  ProductSources,
} from '../../../../shared/models/product.interface';
import SelectProductList from './SelectProductList';
import ProductCategorySelector from './ProductCategorySelector';
import SearchField from '../../components/SearchField';
import NodonSelect from '../../components/NodonSelect';
import {
  QuantityUnit,
  selectableQuantityUnits,
} from '../../../../shared/models/unit.interface';
import { IElement } from '../../../../shared/models/project.interface';
import { useProductStoreState } from '../../store/product';
import { capitalize, orderBy } from 'lodash';
import ProductSelectorTabMenu from './ProductSelectorTabMenu';
import { useUsedProductIds } from '../../hooks/useUsedProductIds';
import { TEMPLATE_ORGANIZATION } from '../../../../shared/constants';
import { useAppContext, useSelectedVersion } from '../../store/ui';
import { getProductById } from '../../../../shared/helpers/product_helpers';
import { openEditProductDialog } from './EditProductDialog';
import { isDefined } from '../../../../shared/helpers/array_helpers';

export type ProductSelectorTabMenuValue =
  | 'all'
  | 'generic'
  | 'ökobaudat'
  | 'epd'
  | 'recent';

export type SelectableSource = ProductSources | ProductSelectorTabMenuValue;

interface ProductSelectorProps {
  open: boolean;
  parent?: IElement;
  productId?: ProductID;
  defaultSource?: ProductSources;
  keepOpen?: boolean;
  onSave: (
    productId: string,
    genericProductId?: string,
    applyEPD?: boolean,
  ) => void;
  onClose: () => void;
}

const hasProps = (product: Product, parts: string[]): boolean => {
  let cur = product.categories as Record<string, unknown>;
  for (const part of parts) {
    if (part === parts[parts.length - 1]) {
      return !!cur[part];
    }
    if (!cur[part]) {
      return false;
    }
    cur = cur[part] as Record<string, unknown>;
  }

  return false;
};

const getProductSources = (products: Product[]): string[] =>
  products.reduce((sources, { source }) => {
    if (source && !sources.includes(source)) {
      sources.push(source);
    }
    return sources;
  }, [] as string[]);

const getProductCategories = (products: Product[]): Record<string, unknown> =>
  products.reduce(
    (categories, product) =>
      deepmerge(
        categories,
        product.organizations?.[0] === TEMPLATE_ORGANIZATION
          ? { ...product.categories, Custom: false, Library: true }
          : product.categories,
      ),
    {},
  );

const hasProductsWithSource = (products: Product[], source: string): boolean =>
  getProductSources(products).includes(source);

const getCategorySource = (
  source: string,
  organizations?: string[],
): string => {
  if (source === 'ökobaudat') {
    return 'ILCD';
  }
  if (source === 'custom' && organizations?.[0] === TEMPLATE_ORGANIZATION) {
    return 'Library';
  }
  return capitalize(source);
};

const ProductSelector: React.FC<ProductSelectorProps> = ({
  open,
  parent,
  productId,
  defaultSource = 'Boverket',
  keepOpen,
  onSave,
  onClose,
}) => {
  const { classes } = useStyles();
  const selectedVersion = useSelectedVersion();
  const [usedProductIds] = useUsedProductIds();
  const { products, productsLookup, deleteProduct } = useProductStoreState(
    'products',
    'productsLookup',
    'deleteProduct',
  );

  const [searchString, setSearchString] = useState('');

  const [selectedUnit, setSelectedUnit] = useState('kg' as QuantityUnit);

  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(
    undefined,
  );
  const [expandedCategory, setExpandedCategory] = useState<string[]>([
    'Boverket',
  ]);
  const [selectedSource, setSelectedSource] =
    useState<SelectableSource>(defaultSource);

  const product = useMemo(() => {
    if (productId) {
      return getProductById(productsLookup, selectedVersion, productId);
    }
  }, [productsLookup, productId, selectedVersion]);

  const appContext = useAppContext();

  const productsBySource: Record<string, Product[]> = useMemo(() => {
    return products.reduce<Record<string, Product[]>>(
      (productsBySource, product) => {
        const { source } = product;

        if (source && !productsBySource[source]) {
          productsBySource[source] = [];
        }
        if (source) {
          productsBySource[source]?.push(product);
        }
        return productsBySource;
      },
      {
        all: products,

        recent: usedProductIds
          .map((id) => products.find((product) => product.id === id))
          .filter((product) => product !== undefined),
      } as Record<string, Product[]>,
    );
  }, [products, usedProductIds]);

  const fuse = useMemo(
    () =>
      new Fuse(productsBySource[selectedSource] || [], {
        includeScore: true,
        minMatchCharLength: 2,
        threshold: 0.3,
        distance: 1000, // Distance * threshold is the amount of characters searched
        keys: ['id', 'name'],
      }),
    [selectedSource, productsBySource],
  );

  const filteredProducts = useMemo(() => {
    const productsBySelectedSource = productsBySource[selectedSource];

    const products =
      productsBySelectedSource && searchString
        ? fuse
            .search(searchString)
            .map((item) => productsBySelectedSource[item.refIndex])
        : productsBySource[selectedSource] || [];

    const orderedProducts = orderBy(products, ['name'], ['asc']).filter(
      isDefined,
    );

    if (selectedCategory) {
      const parts = selectedCategory.split('.');

      return orderedProducts.filter((product) => {
        return selectedCategory === 'Library'
          ? product.organizations?.[0] === TEMPLATE_ORGANIZATION
          : hasProps(product, parts) &&
              product.organizations?.[0] !== TEMPLATE_ORGANIZATION;
      });
    }

    return orderedProducts;
  }, [fuse, selectedSource, productsBySource, searchString, selectedCategory]);

  const categories = useMemo(
    () => getProductCategories(productsBySource[selectedSource] || []),
    [productsBySource, selectedSource],
  );

  const handleDeleteProduct = useCallback(
    (product: Product): void => {
      void deleteProduct(product.id);
      if (
        !hasProductsWithSource(
          products.filter((p) => p.id !== product.id),
          selectedSource,
        )
      ) {
        setSelectedSource('all');
      }
    },
    [deleteProduct, products, selectedSource],
  );

  const handleClose = useCallback(
    (clickEvent?: MouseEvent<HTMLButtonElement> | Record<string, never>) => {
      if (!keepOpen || clickEvent) {
        appContext({ productSelectorOpen: false });
        onClose();
      }
    },
    [appContext, keepOpen, onClose],
  );

  const handleSelectProduct = useCallback(
    (product: Product, applyEPD = false) => {
      onSave(product.id, product.generic_id, applyEPD);
      handleClose();
    },
    [handleClose, onSave],
  );

  const openCreateProductDialog = useCallback(async () => {
    const product = await openEditProductDialog({ parent });
    if (product) {
      handleSelectProduct(product);
    }
  }, [handleSelectProduct, parent]);

  const handleChange = useCallback(
    (value: string) => setSelectedUnit(value as QuantityUnit),
    [],
  );

  useEffect(() => {
    if (!defaultSource) {
      setSelectedSource('Boverket');
    }
    appContext({ productSelectorOpen: open });
    setSearchString('');
  }, [appContext, defaultSource, open]);

  // Navigate to appropriate tab and select the category to which the product belongs
  useEffect(() => {
    if (!product || !open || defaultSource === 'custom') {
      return;
    }
    const { categories, source } = product;

    const categorySource = getCategorySource(source, product.organizations);

    const categoryRecord: IAnyBoolean | undefined = (
      categories as Record<string, any>
    )[categorySource];

    setSelectedSource(source);
    setExpandedCategory([categorySource]);

    if (categoryRecord) {
      const category = Object.keys(categoryRecord)[0];

      setSelectedCategory(
        category ? `${categorySource}.${category}` : categorySource,
      );
    }
  }, [defaultSource, open, product]);

  if (!open) {
    return null;
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.dialog }}
    >
      <Box
        mt={2}
        display="flex"
        flexDirection="column"
        height="100vh"
        alignItems="center"
      >
        <Box
          width="100%"
          height="20%"
          maxHeight={200}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            display="flex"
            flexDirection="row-reverse"
            justifyContent="flex-start"
            width="100%"
            mr={2}
            position="absolute"
            top={0}
          >
            <IconButton onClick={handleClose} size="large">
              <Close />
            </IconButton>
          </Box>

          <Typography variant="h5">
            {product?.name ?? parent?.name ?? parent?.fallbackName}
          </Typography>

          <Box width="80%" display="flex" m={2}>
            <Box flex={1} mr={2}>
              <SearchField
                value={searchString}
                onChange={setSearchString}
                label={
                  <FormattedMessage
                    id="product_selector.search"
                    defaultMessage="Search"
                  />
                }
                sx={{ width: '100%' }}
              />
            </Box>
            <Box display="flex" alignItems="center">
              <Box mr={1}>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<Add />}
                  onClick={openCreateProductDialog}
                >
                  <FormattedMessage
                    id="product_selector.new_product"
                    defaultMessage="New product"
                  />
                </Button>
              </Box>
            </Box>
          </Box>
          <Box display="flex" gap="2rem">
            <Paper>
              <ProductSelectorTabMenu
                selectedSource={selectedSource}
                defaultSource={defaultSource}
                setSelectedSource={setSelectedSource}
                setSelectedCategory={setSelectedCategory}
              />
            </Paper>
            <NodonSelect
              buttonLabel={`kg CO2e per ${selectedUnit}`}
              options={selectableQuantityUnits.filter((unit) => unit !== 'l')}
              fullWidth
              onChange={handleChange}
            />
          </Box>
        </Box>

        <Box width="100%" mt={2}>
          <Divider />
        </Box>

        <Box display="flex" flex={1} mt={2} width="100%" height="80%">
          {selectedSource !== 'recent' && selectedSource !== 'all' ? (
            <Box minWidth="20%" height="100%" pb={2}>
              <ProductCategorySelector
                categories={categories}
                onChange={setSelectedCategory}
                selectedCategory={selectedCategory}
                expandedCategory={expandedCategory}
                setExpandedCategory={setExpandedCategory}
              />
            </Box>
          ) : null}
          <Divider orientation="vertical" flexItem />
          <Box flex={1}>
            <SelectProductList
              products={filteredProducts}
              productSelectorIsOpen={open}
              productId={productId}
              selectedUnit={selectedUnit}
              onSelect={handleSelectProduct}
              onDelete={handleDeleteProduct}
            />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

const useStyles = makeStyles()(() => ({
  dialog: {
    overflow: 'hidden',
  },
}));

export default ProductSelector;
