import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { Box, OutlinedTextFieldProps } from '@mui/material';
import { selectableUnitHarmonizer } from '../../../../../shared/helpers/unit_helpers';
import ElementRecipe from '../../SidePanel/Element/ElementRecipe';
import ExpressionInput from '../../ExpressionInput';
import { getExpression } from '../../../../../shared/helpers/expression_solving_helpers';
import { IElement } from '../../../../../shared/models/project.interface';
import { useIsSelected, useUIState } from '../../../store/ui/ui.hook';
import {
  useElementExpressionVariablesById,
  useUpdateExpression,
} from '../../../hooks/useElementExpressionVariables';
import {
  getDefaultElementQuantityProperty,
  getElementQuantityRecord,
  isElementQuantityName,
  isElementQuantityProperty,
} from '../../../../../shared/helpers/element_quantity_helpers';
import { useIsReadonly } from '../../../hooks/user.hook';

import { useToggleElementExpanded } from '../../../hooks/expand-elements.hook';
import ExpandButton from '../Buttons/ExpandButton';
import { Row } from '../Row';
import { RowCell } from '../RowCell';
import {
  ROOT_CELL_WIDTH,
  ROOT_CELL_WIDTH_RESPONSIVE,
  LIST_SPACING,
  CONTENT_CELL_WIDTH,
  CONTENT_CELL_WIDTH_RESPONSIVE,
  ELEMENT_LIST_ITEM_HEIGHT,
} from '../list.constants';
import { useBooleanState } from '../../../hooks/hooks';
import {
  useIsKebabMenuOpen,
  useTriggerContextKebabMenu,
} from '../../kebab-menu/kebab-menu.hooks';
import { LIST_BORDER, useListRowStyles } from '../list.style';
import { useNavigateTo } from '../../../hooks/router.hooks';
import { useMouseEventCallback } from '../../../hooks/events.hook';
import CreateSiblingButton, {
  useShouldShowCreateSiblingButton,
} from '../../CreateSiblingButton';
import { hasChildren } from '../../../../../shared/helpers/recursive_element_helpers';
import { isProductCategoryElement } from '../../../../../shared/helpers/element_category_helpers';
import { useSortedElementCommments } from '../../../store/comment/comments.hook';
import CommentsIconButton from '../../Comments/CommentsIconButton';
import ElementBarCharts from '../../charts/ElementBarCharts';
import ElementPropertyExpressionInput from '../../SidePanel/Element/Property/ElementPropertyExpression';
import { getElementVersionId } from '../../../../../shared/helpers/element-version.helpers';
import ElementKebabMenu from '../KebabMenus/ElementKebabMenu';
import { useIsDeactivated } from '../../../hooks/useElement';
import InlineTextField from '../../InlineTextField';
import { useSetNameOfElements } from '../../../hooks/element-name.hook';
import { useParentMainCategoryId } from '../../../hooks/main-categories.hook';
import MainCategoryButton from '../Buttons/MainCategoryButton';
import VisibilityButton from '../Buttons/VisibilityButton';
import { useIsFirstElementVersionInList } from '../../../hooks/element-version.hook';
import { getKeys } from '../../../../../shared/helpers/object_helpers';
import NodonSelect from '../../NodonSelect';
import { sortBy } from 'lodash';
import { useUpdateElements } from '../../../store/project';
import { quantityIcons } from '../../Icon';

interface IElementListItemInput {
  element: IElement;
  autoFocus?: boolean;
  indentation?: number;
}

const ElementListItem: React.FC<IElementListItemInput> = ({
  element,
  autoFocus,
  indentation = 0,
}) => {
  const { classes: listClasses } = useListRowStyles();

  const navigateTo = useNavigateTo();
  const updateExpression = useUpdateExpression(element);
  const toggleElementExpanded = useToggleElementExpanded(element);
  const setNameOfElements = useSetNameOfElements();

  const {
    setAddedElementId,
    selectedElementCategoryId,
    setShowProjectDetailsEditor,
  } = useUIState(
    'setAddedElementId',
    'selectedElementCategoryId',
    'setShowProjectDetailsEditor',
  );

  const comments = useSortedElementCommments(element.id);
  const variables = useElementExpressionVariablesById(element.id);
  const updateElements = useUpdateElements();

  const id = element.id;
  const readonly = useIsReadonly();
  const selected = useIsSelected(element);
  const deactivated = useIsDeactivated(element);
  const showCreateSiblingButton = useShouldShowCreateSiblingButton(element);
  const elementVersionId = getElementVersionId(element);
  const isKebabMenuOpen = useIsKebabMenuOpen(id);
  const triggerContextMenu = useTriggerContextKebabMenu(id);
  const isFirstVersionInList = useIsFirstElementVersionInList(element);
  const parentMainCategoryId = useParentMainCategoryId(element);
  const showMainCategoryIcon =
    !!parentMainCategoryId && !selectedElementCategoryId && !deactivated;

  // Only allow expand if ProductCategoryElements and element has children
  const isExpandAllowed =
    hasChildren(element) &&
    (!isProductCategoryElement(element) || element.count.resolved);

  const [hover, startHover, endHover] = useBooleanState();

  const defaultQuantityProperty = useMemo(
    () => getDefaultElementQuantityProperty(element),
    [element],
  );

  const expressionInputValue = useMemo(
    () => getExpression(element.count),
    [element.count],
  );

  const onRowClick = useMouseEventCallback(
    () => {
      if (!isKebabMenuOpen) {
        navigateTo({ elementId: element.id });
        setShowProjectDetailsEditor(false);
      }
    },
    { ignoreInputEvents: true },
  );

  const [isEditingName, startEditingName, stopEditingName] =
    useBooleanState(false);

  const nameInputProps: Partial<OutlinedTextFieldProps> = useMemo(
    () => ({
      autoFocus,
      onFocus: (e) => {
        if (autoFocus) {
          e.currentTarget.select();
        }
      },
      onBlur: () => {
        if (autoFocus) {
          setAddedElementId(undefined);
        }
      },
    }),
    [autoFocus, setAddedElementId],
  );

  const saveName = useCallback(
    async (name: string) => {
      await setNameOfElements(name, element);
      stopEditingName();
    },
    [element, setNameOfElements, stopEditingName],
  );

  const handleNameInputClick = useMouseEventCallback(() => {
    if (selected || !elementVersionId) {
      startEditingName();
    }
  });

  const onSelectedQuantityChange = useCallback(
    async (name: string) => {
      if (isElementQuantityName(name) && name !== element.selectedQuantity) {
        return await updateElements({ id: element.id, selectedQuantity: name });
      }
    },
    [element.id, element.selectedQuantity, updateElements],
  );

  const selectableQuantityNames = useMemo(() => {
    if (isElementQuantityProperty(defaultQuantityProperty)) {
      const names = getKeys(getElementQuantityRecord(element));
      if (names.length) {
        return sortBy(
          names.map((name) => ({
            value: name,
            icon: quantityIcons[name],
          })),
          'icon',
        );
      }
    }
  }, [defaultQuantityProperty, element]);

  // Start editing the name if autoFocus is set
  useEffect(() => {
    autoFocus && startEditingName();
  }, [autoFocus, startEditingName]);

  return (
    <Box
      width="100%"
      onMouseLeave={endHover}
      onMouseOver={startHover}
      onClick={onRowClick}
      onDoubleClick={toggleElementExpanded}
    >
      <Row
        classes={listClasses}
        deactivated={deactivated}
        height={ELEMENT_LIST_ITEM_HEIGHT}
        onContextMenu={triggerContextMenu}
        hover={hover}
        padding={true}
        selected={selected}
        spacing={LIST_SPACING}
        borderTop={isFirstVersionInList ? LIST_BORDER : undefined}
      >
        {/* CONTENT. Group content in a shared cell to make sure we can align bar charts as one */}
        <RowCell
          width={ROOT_CELL_WIDTH.CONTENT}
          borderRight={LIST_BORDER}
          paddingRight={LIST_SPACING}
        >
          <Row height={ELEMENT_LIST_ITEM_HEIGHT} spacing={LIST_SPACING}>
            {/* Visibility icon */}
            <RowCell width={ROOT_CELL_WIDTH.ICON}>
              {showMainCategoryIcon && (
                <MainCategoryButton id={parentMainCategoryId} />
              )}
              {hover && !showMainCategoryIcon && (
                <VisibilityButton element={element} />
              )}
            </RowCell>

            {/* Expand icon */}
            <RowCell width={ROOT_CELL_WIDTH.ICON} indentation={indentation}>
              <ExpandButton element={element} disabled={!isExpandAllowed} />
            </RowCell>

            {/* Name input */}
            <RowCell
              width={CONTENT_CELL_WIDTH.NAME}
              responsiveWidth={CONTENT_CELL_WIDTH_RESPONSIVE.NAME}
            >
              <InlineTextField
                onClick={handleNameInputClick}
                value={element.name}
                defaultValue={element.fallbackName}
                variant="subtitle1"
                editing={isEditingName}
                onSave={saveName}
                onCancel={stopEditingName}
                autoWidth={true}
                textFieldProps={nameInputProps}
                disabled={readonly || !selected}
                isDeactivated={element.isDeactivated}
              />

              {(hover || selected) && (
                <ElementRecipe
                  element={element}
                  isSelectViewOnly
                  tooltipTitle="Select recipe"
                />
              )}
            </RowCell>

            {/* Expression input */}
            <RowCell align="right" width={CONTENT_CELL_WIDTH.EXPRESSION}>
              {defaultQuantityProperty ? (
                <>
                  <ElementPropertyExpressionInput
                    property={defaultQuantityProperty}
                    element={element}
                  />
                  {!!selectableQuantityNames && (
                    <NodonSelect
                      options={selectableQuantityNames}
                      onChange={onSelectedQuantityChange}
                      buttonStyles={{
                        visibility: hover || selected ? 'visible' : 'hidden',
                      }}
                      tooltipTitle="Select quantity"
                    />
                  )}
                </>
              ) : (
                <ExpressionInput
                  id={element.id}
                  expressionValue={expressionInputValue}
                  unit={selectableUnitHarmonizer(element.unit)}
                  variables={variables}
                  disabled={readonly || !!elementVersionId}
                  onSave={updateExpression}
                />
              )}
            </RowCell>
          </Row>
        </RowCell>

        {/* Bar chart */}
        <RowCell
          width={ROOT_CELL_WIDTH.BAR}
          responsiveWidth={ROOT_CELL_WIDTH_RESPONSIVE.BAR}
        >
          <ElementBarCharts element={element} />
        </RowCell>

        <RowCell width={CONTENT_CELL_WIDTH.ICON} align="right">
          {comments.length > 0 && <CommentsIconButton comments={comments} />}
        </RowCell>

        {/* Kebab menu */}
        <RowCell width={CONTENT_CELL_WIDTH.ICON} hideOnPrint align="center">
          {(hover || isKebabMenuOpen) && (
            <ElementKebabMenu element={element} onClose={endHover} />
          )}
        </RowCell>
      </Row>
      {showCreateSiblingButton && (
        <CreateSiblingButton element={element} indentation={indentation} />
      )}
    </Box>
  );
};

export default memo(ElementListItem);
