import {
  ElementCategoryID,
  type IElementCategory,
} from '../../../models/element_categories.interface';
import {
  ElementPropertyInputType,
  IFactoryProperty,
} from '../../../models/element_property.interface';

enum HeatingPropertyName {
  Type = 'type',
  MaxEffect = 'max_effect',
  COP = 'cop',
  HeatSource = 'heat_source',
  ElectricPeakEffect = 'electric_peak_effect',
  DistributionSystem = 'distribution_system',
}

enum TypeOptions {
  DistrictHeating = 'district_heating',
  HeatPump = 'heat_pump',
  Boiler = 'boiler',
  //   Furnace = 'furnace',
  DirectElectricHeating = 'direct_electric_heating',
}

enum HeatSourceOptions {
  Air = 'air',
  Geothermal = 'geothermal',
  Water = 'water',
  ExhaustAir = 'exhaust_air',
  Hybrid = 'hybrid',
}

enum DistributionSystemOptions {
  WaterBased = 'water_based',
}

const properties: IFactoryProperty[] = [
  {
    name: HeatingPropertyName.Type,
    type: ElementPropertyInputType.Select,
    options: Object.values(TypeOptions),
  },
  {
    name: HeatingPropertyName.MaxEffect,
    type: ElementPropertyInputType.Expression,
    unit: 'W',
    fallbackCount: 2000,
    description: 'Utilising electric peak',
  },
  {
    name: HeatingPropertyName.COP,
    type: ElementPropertyInputType.Expression,
    unit: 'none',
    fallbackCount: 3.4,
    description: 'Coefficient of Performance',
  },
  {
    name: HeatingPropertyName.HeatSource,
    type: ElementPropertyInputType.Select,
    options: Object.values(HeatSourceOptions),
  },
  {
    name: HeatingPropertyName.ElectricPeakEffect,
    type: ElementPropertyInputType.Expression,
    unit: '%',
    fallbackCount: 0.5,
  },
  {
    name: HeatingPropertyName.DistributionSystem,
    type: ElementPropertyInputType.Select,
    options: Object.values(DistributionSystemOptions),
  },
];

export const heating: IElementCategory = {
  id: ElementCategoryID.Heating,
  name: 'Heating',
  properties,
};
