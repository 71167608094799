import {
  ElementPropertyInputType,
  IFactorySelectProperty,
} from '../../models/element_property.interface';

export const SbefCodeGroups: Record<string, string[]> = {
  'Sanering och rivning': [
    'Sammansatta',
    'Demontering',
    'Sanering och lättrivning',
    'Tung rivning',
    'Efterlagning',
    '',
    'Håltagning',
    'Arbeten för installationer',
    '',
    '',
  ],
  Mark: [
    'Sammansatta',
    'Röjning, rivning, flyttning',
    'Schakter, fyllning',
    'Markförstärkning, dränering',
    '',
    'Ledningar, kulvertar, tunnlar',
    'Vägar, planer',
    'Trädgård',
    'Markutr. stödmurar, komplementbyggnad.',
    'Mark övrigt',
  ],
  Husunderbyggnad: [
    'Sammansatta',
    '',
    'Schakt, fyllning',
    'Markförstärkning, dränering',
    'Grund-konstruktioner',
    'Kulvertar, tunnlar',
    'Garage',
    'Platta på mark',
    'Huskompl. Husunderbyggnad',
    'Husunderbyggnad övrigt',
  ],
  Stomme: [
    'Sammansatta',
    'Stomme-väggar',
    'Stomme-pelare',
    'Prefab',
    'Stomme bjälklag, balkar',
    'Smide',
    'Stomme, trappor, hisschakt',
    'Samverkade takstomme',
    'Huskompl. stomme',
    'Stomme övrigt',
  ],
  Yttertak: [
    'Sammansatta',
    'Takstomme',
    'Taklagskomplettering',
    'Taktäckning',
    'Takfot och gavlar',
    'Öppningskompletteringar yttertak',
    'Plåt',
    'Terrasstak, altaner',
    'Huskompl. yttertak',
    'Yttertak övrigt',
  ],
  Fasader: [
    'Sammansatta',
    'Stomkomplettering/utfackning',
    '',
    'Fasadbeklädnad',
    '',
    '',
    'Fönster, dörrar, partier, portar',
    '',
    'Huskompl. ytterväggar',
    '',
  ],
  'Stomkompl. rumsbildn.': [
    'Sammansatta',
    'Insida yttervägg',
    'Undergolv',
    'Innerväggar',
    'Innertak',
    'Invändiga dörrar, glaspartier',
    'Invändiga trappor',
    '',
    'Huskompl. rumsbildning',
    'Rumsbildning övrigt',
  ],
  'Invändiga ytskikt rumskompl.': [
    'Sammansatta',
    '',
    'Ytskikt golv, trappor',
    'Ytskikt vägg',
    'Ytskikt tak, undertak',
    'Målning',
    'Vitvaror',
    'Skåpssnickerier',
    'Rumskompl.',
    'Rumskompl. övrigt',
  ],
  Installationer: [
    'Sammansatta',
    'Integrerade solceller',
    'Process',
    'Storkök',
    'Sanitet, värme',
    'Kyla, luft',
    'El',
    'Transport',
    'Styr och regler',
    'Installationer övrigt',
  ],
  'Gemensamma arbeten': [
    'Gem. arbeten sammansatta',
    'Gemensamma arbeten',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  Övrigt: [
    'A5.1: Spill, emballage och avfallshantering',
    'A5.2: Byggarbetsplatsens fordon, maskiner och apparater (energi till drivmedel m.m.)',
    'A5.3: Tillfälliga bodar, kontor, förråd och andra byggnader (energi till uppvärmning m.m.)',
    'A5.4:Byggprocess-ens övriga energivaror (som gasol och diesel för värmare och dylikt, köpt el, fjärrvärme o.s.v.)',
    'A5.5 Övrig miljöpåverkan från byggprocessen, inklusive övergödning vid sprängning, markexploatering, kemikalieanvändning o.s.v.',
  ],
};

export enum SbefCode {
  None = 'none',
  '#0' = '0 Sanering och rivning',
  '#00' = '00 Sammansatta',
  '#01' = '01 Demontering',
  '#02' = '02 Sanering och lättrivning',
  '#03' = '03 Tung rivning',
  '#04' = '04 Efterlagning',
  '#05' = '05',
  '#06' = '06 Håltagning',
  '#07' = '07 Arbeten för installationer',
  '#08' = '08',
  '#09' = '09',

  '#1' = '1 Mark',
  '#10' = '10 Sammansatta',
  '#11' = '11 Röjning, rivning, flyttning',
  '#12' = '12 Schakter, fyllning',
  '#13' = '13 Markförstärkning, dränering',
  '#14' = '14',
  '#15' = '15 Ledningar, kulvertar, tunnlar',
  '#16' = '16 Vägar, planer',
  '#17' = '17 Trädgård',
  '#18' = '18 Markutr. stödmurar, komplementbyggnad.',
  '#19' = '19 Mark övrigt',

  '#2' = '2 Husunderbyggnad',
  '#20' = '20 Sammansatta',
  '#21' = '21',
  '#22' = '22 Schakt, fyllning',
  '#23' = '23 Markförstärkning, dränering',
  '#24' = '24 Grund-konstruktioner',
  '#25' = '25 Kulvertar, tunnlar',
  '#26' = '26 Garage',
  '#27' = '27 Platta på mark',
  '#28' = '28 Huskompl. Husunderbyggnad',
  '#29' = '29 Husunderbyggnad övrigt',

  '#3' = '3 Stomme',
  '#30' = '30 Sammansatta',
  '#31' = '31 Stomme-väggar',
  '#32' = '32 Stomme-pelare',
  '#33' = '33 Prefab',
  '#34' = '34 Stomme bjälklag, balkar',
  '#35' = '35 Smide',
  '#36' = '36 Stomme, trappor, hisschakt',
  '#37' = '37 Samverkade takstomme',
  '#38' = '38 Huskompl. stomme',
  '#39' = '39 Stomme övrigt',

  '#4' = '4 Yttertak',
  '#40' = '40 Sammansatta',
  '#41' = '41 Takstomme',
  '#42' = '42 Taklagskomplettering',
  '#43' = '43 Taktäckning',
  '#44' = '44 Takfot och gavlar',
  '#45' = '45 Öppningskompletteringar yttertak',
  '#46' = '46 Plåt',
  '#47' = '47 Terrasstak, altaner',
  '#48' = '48 Huskompl. yttertak',
  '#49' = '49 Yttertak övrigt',

  '#5' = '5 Fasader',
  '#50' = '50 Sammansatta',
  '#51' = '51 Stomkomplettering/utfackning',
  '#52' = '52',
  '#53' = '53 Fasadbeklädnad',
  '#54' = '54',
  '#55' = '55 Fönster, dörrar, partier, portar',
  '#56' = '56',
  '#57' = '57',
  '#58' = '58 Huskompl. ytterväggar',
  '#59' = '59',

  '#6' = '6 Stomkompl. rumsbildn.',
  '#60' = '60 Sammansatta',
  '#61' = '61 Insida yttervägg',
  '#62' = '62 Undergolv',
  '#63' = '63 Innerväggar',
  '#64' = '64 Innertak',
  '#65' = '65 Invändiga dörrar, glaspartier',
  '#66' = '66 Invändiga trappor',
  '#67' = '67',
  '#68' = '68 Huskompl. rumsbildning',
  '#69' = '69 Rumsbildning övrigt',

  '#7' = '7 Invändiga ytskikt rumskompl.',
  '#70' = '70 Sammansatta',
  '#71' = '71',
  '#72' = '72 Ytskikt golv, trappor',
  '#73' = '73 Ytskikt vägg',
  '#74' = '74 Ytskikt tak, undertak',
  '#75' = '75 Målning',
  '#76' = '76 Vitvaror',
  '#77' = '77 Skåpssnickerier',
  '#78' = '78 Rumskompl.',
  '#79' = '79 Rumskompl. övrigt',

  '#8' = '8 Installationer',
  '#80' = '80 Sammansatta',
  '#81' = '81 Integrerade solceller',
  '#82' = '82 Process',
  '#83' = '83 Storkök',
  '#84' = '84 Sanitet, värme',
  '#85' = '85 Kyla, luft',
  '#86' = '86 El',
  '#87' = '87 Transport',
  '#88' = '88 Styr och regler',
  '#89' = '89 Installationer övrigt',

  '#9' = '9 Gemensamma arbeten',
  '#90' = '90 Gem. arbeten sammansatta',
  '#91' = '91 Gemensamma arbeten',
  '#92' = '92',
  '#93' = '93',
  '#94' = '94',
  '#95' = '95',
  '#96' = '96',
  '#97' = '97',
  '#98' = '98',
  '#99' = '99',

  '#101' = '101 A5.1: Spill, emballage och avfallshantering',
  '#102' = '102 A5.2: Byggarbetsplatsens fordon, maskiner och apparater (energi till drivmedel m.m.)',
  '#103' = '103 A5.3: Tillfälliga bodar, kontor, förråd och andra byggnader (energi till uppvärmning m.m.)',
  '#104' = '104 A5.4:Byggprocess-ens övriga energivaror (som gasol och diesel för värmare och dylikt, köpt el, fjärrvärme o.s.v.)',
  '#105' = '105 A5.5 Övrig miljöpåverkan från byggprocessen, inklusive övergödning vid sprängning, markexploatering, kemikalieanvändning o.s.v.',
}

export enum PropertyName {
  sbefCode = 'sbef_code',
}

export const sbefCodeProperty: IFactorySelectProperty = {
  type: ElementPropertyInputType.Select,
  name: PropertyName.sbefCode,
  count: SbefCode['None'],
  options: Object.values(SbefCode),
};
