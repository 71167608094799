import React, { useMemo } from 'react';
import {
  IBuildingVersion,
  OneOfElements,
} from '../../../shared/models/project.interface';
import {
  Results,
  ResultsRecord,
  QuantityUnit,
  emptyConversionFactors,
} from '../../../shared/models/unit.interface';
import {
  getQuantityFromResultsRecord,
  getElementResults,
  getResultsRecordFromElementResults,
} from '../../../shared/helpers/results.helpers';
import { isElement } from '../../../shared/helpers/recursive_element_helpers';
import { useSelectedVersion } from '../store/ui';
import { getElementTotalCount } from '../../../shared/helpers/expression_solving_helpers';
import {} from './sort.hook';
import { useSortedFlattenedElements } from './filter-elements.hook';
import { maxBy } from 'lodash';
import { isMainCategoryElement } from '../../../shared/templates/categories';
import { useProposals } from './proposals.hook';
import { IProposal } from '../../../shared/models/proposals.interface';
import { ItemOrItemId } from '../../../shared/models/type_helpers.interface';
import { required } from '../../../shared/helpers/function_helpers';
import { useElementById, useVersions } from './useElement';

/**
 * Get latest results for each element in the entire project.
 */
export function useProjectResultsRecord(): ResultsRecord {
  const versions = useVersions();

  return React.useMemo(() => {
    const factors = versions.map((version) =>
      getResultsRecordFromElementResults(version),
    );
    return factors.reduce((acc, curr) => ({ ...acc, ...curr }), {});
  }, [versions]);
}

/**
 * Get latest conversion factors totals for each element for a specific version.
 * @param version The version to get the conversion factors for, if not provided, the selectedVersion is used.
 */
export function useVersionResultRecord(
  version?: IBuildingVersion,
): ResultsRecord {
  const selectedVersion = useSelectedVersion();
  if (!version) {
    version = selectedVersion;
  }

  return React.useMemo(() => {
    if (!version) {
      return {};
    }
    return getResultsRecordFromElementResults(version);
  }, [version]);
}

/**
 * Get the largest results in version
 * @param byProperty Defaults to 'co2e_total'
 */
export const useElementMaxResults = (
  byProperty: keyof Results = 'co2e_total',
): Results => {
  const elements = useSortedFlattenedElements();
  const resultRecord = useVersionResultRecord();
  const proposals = useProposals();
  const resultRecords = useMemo(
    () => [
      resultRecord,
      ...proposals.map((proposal) => proposal.resultsRecord ?? {}),
    ],
    [proposals, resultRecord],
  );

  return useMemo(() => {
    const resultArray: Results[] = elements
      .filter((e) => !isMainCategoryElement(e)) // No main category elements
      .filter((e) => !isElement(e) || (!e.isDeactivated && !e.isHidden)) // Filter out deactivated and hidden elements
      .flatMap((el) =>
        resultRecords.map((r) => {
          const elementResult = r[el.id];

          if (!elementResult) {
            return { ...emptyConversionFactors };
          }
          return elementResult;
        }),
      );

    return maxBy(resultArray, byProperty) ?? { ...emptyConversionFactors };
  }, [elements, byProperty, resultRecords]);
};

/**
 * Use results for a specific elements
 * @param element
 * @param inProposal
 * @returns
 */
export const useElementResults = (
  elementOrId: ItemOrItemId<OneOfElements> | undefined,
  inProposal?: ItemOrItemId<IProposal>,
): Results => {
  const version = required(useSelectedVersion());
  const element = useElementById(elementOrId);

  return useMemo(
    () => getElementResults(version, element, inProposal),
    [version, element, inProposal],
  );
};

export const useProjectVersionsMax = (
  unit: QuantityUnit = 'co2e_total',
): number => {
  const versions = useVersions();
  const projectsTotals = useProjectResultsRecord();
  return useMemo(() => {
    const versionFactors = versions.map((version) =>
      getQuantityFromResultsRecord(projectsTotals, version.id, unit),
    );
    return Math.max(...versionFactors, 0);
  }, [projectsTotals, unit, versions]);
};

/**
 * Get how much of an element is used in the project. Will consider quantities that are absolute values.
 * Also considers older count values which are relative (multiplied) with parent count.
 * parent.count = 2pcs, child.count = 6m2 => totalCount = 12m2
 * @param element
 * @returns
 */
export const useElementTotalCount = (element?: OneOfElements): number => {
  const selectedVersion = useSelectedVersion();
  return useMemo(
    () =>
      selectedVersion && element
        ? getElementTotalCount(selectedVersion, element)
        : 0,
    [element, selectedVersion],
  );
};
