import { ProductID, ProductRecord } from './product.interface';
import { ModelTimestamps } from './base.interface';
import { RecipeID } from './recipe.interface';
import { ElementCategoryID } from './element_categories.interface';
import { ActivityId } from './activity_class.interface';
import { IElementProperty } from './element_property.interface';
import { Results, QuantityUnit } from './unit.interface';
import { Activity } from './activities.interface';
import {
  ElementQuantityName,
  ElementQuantityRecord,
} from './element_quantities.interface';
import { RequireProperties } from './type_helpers.interface';
import { IProjectFolder, ProjectFolderId } from './folder.interface';
import { IProposal } from './proposals.interface';

export interface IID {
  id: string;
}

export type ProjectElementProductID = string;
export type IElementID = string;

export interface FootprintCoordinate {
  lat: number;
  lng: number;
}

export interface FootprintMetadata {
  area: number;
  perimeter: number;
  coordinates: FootprintCoordinate[];
}

export interface IStorey {
  name?: string;
  gfa?: number;
  perimeter?: number;
  inner_height?: number;
}

export interface ProjectMetadata {
  building_footprint: FootprintMetadata;
  gfa_building?: number;
  building_perimeter?: number;
  below_ground: number;
  storeys: IStorey[];
  activity_id?: ActivityId;
  activities?: Activity[];
}

export interface ProjectUser {
  uid: string;
  write: boolean;
}

export type ProjectDesignID = string;

export type ProjectID = number;

export interface Project extends ModelTimestamps {
  id: ProjectID;
  name: string;
  owner: string;
  organizations?: string[];

  template: boolean;
  archived?: boolean;
  locked?: boolean;
  description?: string;
  sharing_key?: string | null;
  location?: number;
  parent_id?: string | null;

  /**
   * List of buildings. Currently only 1 item
   */
  buildings: IBuilding[];
}

// TODO: Should this differ from Project?
// Shouldn't it rather just be a subset of properties inherited from Project?
export interface IProjectInfo
  extends Omit<Project, 'buildings' | 'id' | 'template'> {
  id: string;
  parent_id: ProjectFolderId | null;
  location: number;

  kind: ElementKind.ProjectInfo;

  /**
   * List of IBuildingVersions in project
   */
  versionIds: IElementID[];
  gfa?: number;
}

export type OneOfProjectListElements = IProjectInfo | IProjectFolder;

export interface ExpressionValue {
  resolved: number;
  expression: string;
  formatted: string;
}

export enum ElementKind {
  Product = 'product',
  Element = 'element',
  Version = 'version',
  ProjectFolder = 'project-folder',
  ProjectInfo = 'project-info',
}
export const elementKinds = Object.values(ElementKind);

/**
 * Use ElementKind to get correct type of elements
 */
export type ElementKindMap = {
  [ElementKind.Product]: IProductElement;
  [ElementKind.Element]: IElement;
  [ElementKind.Version]: IBuildingVersion;
};

export type ProjectKindMap = {
  [ElementKind.ProjectFolder]: IProjectFolder;
  [ElementKind.ProjectInfo]: IProjectInfo;
};

export type ListElementKindMap = ElementKindMap & ProjectKindMap;

export type OneOfElementListChildren = IElement | IProductElement;

export type OneOfElementListElements =
  | IBuildingVersion
  | OneOfElementListChildren;

/**
 * All types supported by list
 */
export type OneOfListElements =
  | OneOfElementListElements
  | OneOfProjectListElements;

export type OneOfSelectableElements = IElement | IBuildingVersion;

export type OneOfElements<T extends OneOfListElements = IElement> =
  T extends OneOfElementListElements
    ? OneOfElementListElements
    : OneOfProjectListElements;

/**
 * Elements that might have children
 */
export type OneOfParentElements<T extends OneOfListElements = IElement> =
  T extends OneOfElementListElements
    ? IElement | IBuildingVersion
    : IProjectFolder;

export type OneOfChildElements<T extends OneOfListElements = IElement> =
  T extends OneOfElementListElements
    ? OneOfElementListChildren
    : OneOfProjectListElements;

/**
 * Element types that have element properties
 */
export type OneOfPropertyElements = IElement | IBuildingVersion;

/**
 * Shared properties between all elements
 */
export interface IBaseElement {
  id: IElementID;
  kind: ElementKind;

  /**
   * Calulated values. Can be extended with u-values and other values later
   */
  results?: Results;
}

/**
 * Root element in project
 * Note: Currently only one per project
 */
export interface IBuilding {
  meta: ProjectMetadata;

  /**
   * List of versions/designs
   */
  versions: IBuildingVersion[];
}

/**
 * Basically a ProjectDesign
 * */
export interface IBuildingVersion extends IBaseElement {
  name: string;
  kind: ElementKind.Version;

  /**
   * All products used in this version
   */
  products: ProductRecord;

  /**
   * List of elements.
   * Basically what's shown in Element List
   */
  elements: OneOfChildElements[];

  // TODO: Make sure this always exist by migrating DB
  properties?: IElementProperty[];

  /**
   * List of proposals
   */
  proposals?: IProposal[];
}

export type ElementCountRecord = Partial<Record<QuantityUnit, ExpressionValue>>;

export interface ICountAndUnit {
  count: ExpressionValue;
  unit: QuantityUnit;
}

/**
 * Reference to a Recipe
 */
export interface IRecipeRef {
  recipe_id: RecipeID;
}

/**
 * Reference to an Element Category
 */
export interface IElementCategoryRef {
  category_id: ElementCategoryID;
}
export interface IElement
  extends IBaseElement,
    ICountAndUnit,
    Partial<IRecipeRef>,
    Partial<IElementCategoryRef> {
  kind: ElementKind.Element;
  name: string;

  elements: OneOfChildElements[];
  // TODO: Make sure this always exist by migrating DB
  properties?: IElementProperty[];

  quantity?: ElementQuantityRecord;
  selectedQuantity?: ElementQuantityName;

  fallbackName?: string;

  /**
   * If this is the active version of the element.
   * The rest should be hidden and excluded from calculations
   */
  isActiveVersion?: boolean;

  /**
   * Unique id shared between all versions of the same element
   */
  versionId?: IElementID;

  /**
   * If deactivated, the element should be hidden and excluded from calculations
   */
  isDeactivated?: boolean;

  /**
   * If hidden, the element should be hidden from the user and not be included in calculations
   */
  isHidden?: boolean;
}

/**
 * Element with required element version properties
 */
export type IElementVersion = RequireProperties<IElement, 'versionId'>;

/**
 * Element with reference to a recipe
 */
export type IRecipeElement = IElement & IRecipeRef;

export interface IProductElement extends IBaseElement {
  kind: ElementKind.Product;
  count: ExpressionValue;
  product_id: ProductID;
  generic_product_id?: ProductID;
  unit: QuantityUnit;

  /**
   * generated=true meens that the product was added automatically by an element category
   */
  generated?: boolean;
  // ProjectInfo/Folder
  location?: number;
}

export interface ElementMenuItemData {
  onClick: (argument: any) => void;
  icon: JSX.Element;
  id: string;
  defaultMessage: string;
  disabled?: boolean;
}

export type ElementMenuItemsData = ElementMenuItemData[];
