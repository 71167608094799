import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { Box, OutlinedTextFieldProps } from '@mui/material';
import { useSelectedVersion } from '../../../store/ui/ui.hook';
import { useIsReadonly } from '../../../hooks/user.hook';
import { Row } from '../Row';
import { RowCell } from '../RowCell';
import {
  ROOT_CELL_WIDTH,
  LIST_SPACING,
  CONTENT_CELL_WIDTH,
  CONTENT_CELL_WIDTH_RESPONSIVE,
  ELEMENT_LIST_ITEM_HEIGHT,
  ELEMENT_CO2_BAR_HEIGHT,
  ELEMENT_COST_BAR_HEIGHT,
  ROOT_CELL_WIDTH_RESPONSIVE,
} from '../list.constants';
import { useBooleanState } from '../../../hooks/hooks';
import {
  useIsKebabMenuOpen,
  useTriggerContextKebabMenu,
} from '../../kebab-menu/kebab-menu.hooks';
import { LIST_BORDER, useListRowStyles } from '../list.style';
import { useMouseEventCallback } from '../../../hooks/events.hook';
import InlineTextField from '../../InlineTextField';
import { useUpdateElements } from '../../../store/project';
import { IProposal } from '../../../../../shared/models/proposals.interface';
import {
  useProposalColor,
  useProposalElementScale,
  useProposalResults,
  useProposals,
  useProposalsMaxResults,
  useSelectProposal,
} from '../../../hooks/proposals.hook';
import { updateProposals } from '../../../../../shared/helpers/proposal.helpers';
import { required } from '../../../../../shared/helpers/function_helpers';
import SimpleBarChart from '../../charts/SimpleBarChart';
import ProposalKebabMenu from '../KebabMenus/ProposalKebabMenu';
import { makeStyles } from 'tss-react/mui';
import { formatThousands } from '../../../../../shared/helpers/math_helpers';
import { convertToThousandSEK } from '../../../../../shared/helpers/conversion_helpers';

interface IProposalListItemInput {
  proposal: IProposal;
  autoFocus?: boolean;
}

const ProposalListItem: React.FC<IProposalListItemInput> = ({
  proposal,
  autoFocus,
}) => {
  const { classes: listClasses } = useListRowStyles();
  const { classes, cx } = useStyles();

  const { id, active } = proposal;

  const updateElements = useUpdateElements();
  const triggerContextMenu = useTriggerContextKebabMenu(id);
  const selectProposal = useSelectProposal();

  const proposals = useProposals();
  const proposalColor = useProposalColor(proposal);
  const version = useSelectedVersion();

  const results = useProposalResults(id);
  const resultsMax = useProposalsMaxResults();
  const co2Scale = useProposalElementScale('co2e_total');
  const costScale = useProposalElementScale('sek_A1-A3');

  const readonly = useIsReadonly();
  const isKebabMenuOpen = useIsKebabMenuOpen(id);
  const isFirstProposalInList = proposal === proposals[0];
  const isLastProposalInList = proposal === proposals[proposals.length - 1];

  const [hover, startHover, endHover] = useBooleanState();

  const onRowClick = useMouseEventCallback(
    () => {
      selectProposal(id);
    },
    { ignoreInputEvents: true },
  );

  const onDoubleClick = useMouseEventCallback(() => selectProposal(id));

  const [isEditingName, startEditingName, stopEditingName] =
    useBooleanState(false);

  const nameInputProps: Partial<OutlinedTextFieldProps> = useMemo(
    () => ({
      autoFocus,
      onFocus: (e) => {
        if (autoFocus) {
          e.currentTarget.select();
        }
      },
    }),
    [autoFocus],
  );

  const saveName = useCallback(
    async (name: string) => {
      stopEditingName();
      await updateElements(
        updateProposals(required(version), { id: proposal.id, name }),
      );
    },
    [proposal.id, stopEditingName, updateElements, version],
  );

  const tooltipLabel = `${formatThousands(results.co2e_total)} kgCO2e, ${formatThousands(results['sek_A1-A3'] ?? 0)} SEK`;

  const editName = useCallback(() => {
    startEditingName();
    endHover();
  }, [endHover, startEditingName]);

  const handleNameInputClick = useMouseEventCallback(() => {
    startEditingName();
  });

  // Start editing the name if autoFocus is set
  useEffect(() => {
    autoFocus && startEditingName();
  }, [autoFocus, startEditingName]);

  return (
    <Box
      width="100%"
      component="div"
      onMouseLeave={endHover}
      onMouseOver={startHover}
      onClick={onRowClick}
      onDoubleClick={onDoubleClick}
    >
      <Row
        classes={listClasses}
        height={ELEMENT_LIST_ITEM_HEIGHT}
        onContextMenu={triggerContextMenu}
        hover={hover}
        padding={true}
        spacing={LIST_SPACING}
        borderTop={isFirstProposalInList ? LIST_BORDER : undefined}
        borderBottom={isLastProposalInList ? LIST_BORDER : undefined}
      >
        {/* CONTENT. Group content in a shared cell to make sure we can align bar charts as one */}
        <RowCell
          width={ROOT_CELL_WIDTH.CONTENT}
          borderRight={LIST_BORDER}
          paddingRight={LIST_SPACING}
        >
          <Row height={ELEMENT_LIST_ITEM_HEIGHT} spacing={LIST_SPACING}>
            {/* Name input */}
            <RowCell
              width={CONTENT_CELL_WIDTH.NAME}
              responsiveWidth={CONTENT_CELL_WIDTH_RESPONSIVE.NAME}
              paddingLeft={10}
            >
              <InlineTextField
                onClick={handleNameInputClick}
                value={proposal.name}
                variant="subtitle1"
                editing={isEditingName}
                disabled={readonly || !isEditingName}
                onSave={saveName}
                onCancel={stopEditingName}
                autoWidth={true}
                textFieldProps={nameInputProps}
                bold={!!active}
              />
            </RowCell>

            {/* Kebab menu */}
            <RowCell width={CONTENT_CELL_WIDTH.ICON} hideOnPrint align="center">
              {(hover || isKebabMenuOpen) && (
                <ProposalKebabMenu
                  proposal={proposal}
                  onEditName={editName}
                  onClose={endHover}
                />
              )}
            </RowCell>
          </Row>
        </RowCell>

        {/* Bar chart */}
        <RowCell
          width={ROOT_CELL_WIDTH.BAR}
          responsiveWidth={ROOT_CELL_WIDTH_RESPONSIVE.BAR}
        >
          <Box className={cx(classes.chartContainer)}>
            {/* CO2 Bar */}
            <Box className={cx(classes.chart)}>
              <SimpleBarChart
                tooltip={tooltipLabel}
                value={results.co2e_total}
                maxValue={resultsMax.co2e_total}
                color={proposalColor}
                height={ELEMENT_CO2_BAR_HEIGHT * co2Scale}
              />
            </Box>

            {/* Cost Bar */}
            <Box className={cx(classes.chart)}>
              <SimpleBarChart
                tooltip={tooltipLabel}
                value={results['sek_A1-A3']}
                maxValue={resultsMax['sek_A1-A3']}
                color={'black'}
                height={ELEMENT_COST_BAR_HEIGHT * costScale}
              />
            </Box>
          </Box>
        </RowCell>

        {/* CO2 Label */}
        <RowCell
          width={CONTENT_CELL_WIDTH.UNIT}
          align="center"
          fontSize={12}
          fontWeight={700}
        >
          {formatThousands(results.co2e_total)}
        </RowCell>

        {/* Cost Label */}
        <RowCell
          width={CONTENT_CELL_WIDTH.UNIT}
          align="center"
          fontSize={10}
          fontWeight={400}
        >
          {formatThousands(convertToThousandSEK(results['sek_A1-A3'] ?? 0))}k
        </RowCell>
      </Row>
    </Box>
  );
};

const useStyles = makeStyles()(() => ({
  container: {
    position: 'relative',
    display: 'block',
    height: 'auto',
  },
  dimmed: {
    opacity: 0.5,
  },
  chartContainer: {
    display: 'block',
    width: '100%',
  },
  chart: {
    '&:not(:last-child)': {
      marginBottom: '3px',
    },
  },
}));

export default memo(ProposalListItem);
