import React, { useMemo } from 'react';
import { DeleteForever, Edit } from '@mui/icons-material';
import { KebabMenu } from '../../kebab-menu/KebabMenu';
import { projectActionMenuIconStyles } from '../../../style/constants';
import { IProposal } from '../../../../../shared/models/proposals.interface';
import { useDeleteProposal } from '../../../hooks/proposals.hook';

interface IProposalKebabMenuInput {
  proposal: IProposal;
  onEditName: () => void;
  onClose?: () => void;
}

const ProposalKebabMenu: React.FC<IProposalKebabMenuInput> = ({
  proposal: proposal,
  onEditName,
  onClose,
}) => {
  const { id } = proposal;
  const deleteProposal = useDeleteProposal();

  const items = useMemo(
    () => [
      {
        id: 'rename',
        defaultMessage: 'Edit name',
        icon: <Edit {...projectActionMenuIconStyles} />,
        onClick: onEditName,
      },
      {
        id: 'delete',
        defaultMessage: 'Delete proposal',
        icon: <DeleteForever {...projectActionMenuIconStyles} />,
        onClick: () => deleteProposal(id),
      },
    ],
    [deleteProposal, id, onEditName],
  );

  return <KebabMenu id={id} items={items} onClose={onClose} />;
};

export default ProposalKebabMenu;
