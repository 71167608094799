import { Box, Tooltip } from '@mui/material';
import React, { useMemo } from 'react';

interface MultiLineTooltipProps {
  children: React.ReactNode;
  rows: string[];
}

export const MultiLineTooltip: React.FC<MultiLineTooltipProps> = ({
  children,
  rows,
}) => {
  const title = useMemo(
    () => rows.map((item, index) => <div key={item + index}>{item}</div>),
    [rows],
  );

  return (
    <Tooltip title={<Box textAlign="center">{title}</Box>}>
      <div>{children}</div>
    </Tooltip>
  );
};

export default MultiLineTooltip;
