import type {
  IBuildingVersion,
  IElement,
  IProductElement,
  OneOfChildElements,
  OneOfElements,
  OneOfListElements,
} from '../models/project.interface';
import { Product, ProductRecord } from '../models/product.interface';
import { ElementCategoryID } from '../models/element_categories.interface';
import {
  getElementAndQuantityProperties,
  hasCount,
  isElementPropertyListEqual,
} from './element_property_helpers';
import { isEqualExpressionValues } from './expression_solving_helpers';
import {
  getChildElementsOfVersions,
  getElementById,
  isElement,
  isProductElement,
} from './recursive_element_helpers';
import { ElementPropertySource } from '../models/element_property.interface';
import { getElementCategory } from './element_category_helpers';
import { makeSentence } from './string_helpers';
import {
  getElementCategoryById,
  getMainCategory,
} from '../templates/categories';
import { getSelectableUnitsInConversionFactors } from './unit_helpers';
import { SelectableQuantityUnit } from '../models/unit.interface';
import {
  isActiveElementVersion,
  isElementVersionElement,
  isInactiveElementVersion,
} from './element-version.helpers';
import { OneOfFactoryElements } from './element_factory_helpers';
import { genericProductsLookup } from '../generic_products';

/**
 * Get element names as a sentence
 * @example getElementNames(version, 'element1', 'element2') => 'element1 and element2'
 * @param version
 * @param elementsOrIds
 * @returns
 */
export const getElementNames = (
  version: IBuildingVersion,
  ...elementsOrIds: (OneOfElements | string)[]
): string => {
  const productLookup = version.products;
  const elements = elementsOrIds.map((elementOrId) => {
    if (typeof elementOrId === 'string') {
      return getElementById(version, elementOrId);
    }
    return elementOrId;
  });

  const isElementVersions = elements.every(isElementVersionElement);

  // When removing all element versions, just show the element name of the active element version (the element in the list)
  if (isElementVersions && elements.length >= 2) {
    return getElementName(elements.find(isActiveElementVersion), productLookup);
  }

  const names: string[] = elements.map((e) => getElementName(e, productLookup));

  return makeSentence(...names);
};

/**
 * Get name of an element. If the element is a productElement, the name of the product is returned.
 * @param element
 * @param productLookup Typically version.products
 * @param defaultName If no name exist, use this
 * @returns
 */
export const getElementName = <T extends OneOfListElements>(
  element: T | undefined,
  productLookup: ProductRecord = genericProductsLookup,
  defaultName = 'Unknown',
): string => {
  if (isProductElement(element)) {
    return productLookup[element.product_id]?.name ?? defaultName;
  }
  if (isElement(element)) {
    const mainCategory = getMainCategory(element.category_id);

    return mainCategory
      ? mainCategory.name
      : ((element.name || element.fallbackName) ?? defaultName);
  }
  return element?.name ?? defaultName;
};

/**
 * If the element is generated by the element category and should not be editable
 * @param element
 * @returns
 */
export const isGeneratedProductElement = (
  element: OneOfListElements | undefined,
): element is IProductElement =>
  isProductElement(element) && !!element.generated;

/**
 * If the children is controlled by the element category and should not be editable
 * @param element
 * @returns
 */
export const isElementWithGeneratedChildren = (
  element: OneOfListElements | undefined,
): boolean => !!getElementCategory(element)?.getChildElements;

export const getElementCategoryId = (
  element: OneOfElements | OneOfFactoryElements | undefined,
): ElementCategoryID | undefined =>
  element && ElementPropertySource.Category in element
    ? element[ElementPropertySource.Category]
    : undefined;

export const isEqualElements = (
  a: OneOfChildElements,
  b: OneOfChildElements,
): boolean => {
  if (a?.kind !== b?.kind) {
    return false;
  }

  // ProductElement contains all information needed
  if (isProductElement(a) && isProductElement(b)) {
    return (
      isEqualExpressionValues(a.count, b.count) &&
      a.product_id === b.product_id &&
      a.unit === b.unit &&
      a.generic_product_id === b.generic_product_id
    );
  }
  if (isElement(a) && isElement(b)) {
    if (
      !isEqualExpressionValues(a.count, b.count) ||
      a.name !== b.name ||
      a.unit !== b.unit ||
      a.category_id !== b.category_id ||
      a.elements?.length !== b.elements?.length ||
      a.isDeactivated !== b.isDeactivated
    ) {
      return false;
    }

    const propsA = getElementAndQuantityProperties(a).filter(hasCount);
    const propsB = getElementAndQuantityProperties(b).filter(hasCount);

    if (!isElementPropertyListEqual(propsA, propsB)) {
      return false;
    }

    const childrenA = a.elements.filter(shouldCompareGeneratedProductElement);
    const childrenB = b.elements.filter(shouldCompareGeneratedProductElement);

    // Handle deeper structures
    for (let i = 0; i < childrenA.length; i++) {
      const childA = childrenA[i];
      const childB = childrenB[i];

      if (!childA || !childB || !isEqualElements(childA, childB)) {
        return false;
      }
    }

    return true;
  }
  return false;
};

const shouldCompareGeneratedProductElement = (element: OneOfChildElements) =>
  !isGeneratedProductElement(element) || 'generic_product_id' in element;

/**
 * Test if the element is deactivated. Hidden elements and inactive element versions are also considered deactivated
 * @param element
 * @param countInactiveVersionsAsDeactivated If inactive element versions should be considered deactivated
 * @returns
 */
export const isDeactivated = (
  element: OneOfListElements | undefined,
  countInactiveVersionsAsDeactivated = true,
): boolean => {
  if (isElement(element)) {
    if (element.isDeactivated === true) {
      return true;
    }
    // If the element is an inactive element version, it should be considered deactivated if the flag is set
    if (
      countInactiveVersionsAsDeactivated &&
      isInactiveElementVersion(element)
    ) {
      return true;
    }
  }

  return false;
};

/**
 * If the element is invisible to the user
 * @param element
 * @returns
 */
export const isHidden = (element: OneOfElements | undefined): boolean =>
  isElement(element) && !!element.isHidden;

export const elementHasChild = (element: IElement): boolean =>
  element.elements?.length > 0;

export const replaceProductInElement = (
  element: IProductElement,
  newProduct: Product,
): IProductElement => {
  // If the element is generated, it should keep a reference to the generic/Boverket product
  const generic_product_id =
    element.generated && element.generic_product_id !== newProduct.id
      ? (element.generic_product_id ?? element.product_id)
      : undefined;

  const unitIsUnchanged = getSelectableUnitsInConversionFactors(
    newProduct.conversion_factors,
  ).includes(element.unit as SelectableQuantityUnit);

  const keepSelectedUnit = element.generated || unitIsUnchanged;
  const unit = keepSelectedUnit ? element.unit : newProduct.unit;

  return {
    ...element,
    product_id: newProduct.id,
    generic_product_id,
    unit,
  };
};

export const getRootElements = (version: IBuildingVersion): IElement[] => {
  return getChildElementsOfVersions(version).map((element) => {
    if (element.category_id && !element.fallbackName) {
      const category = getElementCategoryById(element.category_id);
      return { ...element, fallbackName: category.name };
    }
    return element;
  });
};
