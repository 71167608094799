import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  ClickAwayListener,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { EllipsisText } from './EllipsisText';
import { getElementName } from '../../../shared/helpers/element_helpers';
import { useSelectedVersion } from '../store/ui';
import { useVersions } from '../hooks/useElement';
import { useAddVersion, useRemoveElements } from '../store/project';
import Add from '@mui/icons-material/Add';
import Clear from '@mui/icons-material/Clear';
import { mouseEventHandler } from '../../../shared/helpers/event_helpers';
import { useNavigateTo } from '../hooks/router.hooks';

interface IVersionMenuInput {}

const VersionMenu: React.FC<IVersionMenuInput> = () => {
  const selectedVersion = useSelectedVersion();
  const versions = useVersions();
  const addVersion = useAddVersion();
  const removeVersion = useRemoveElements({ showConfirm: true });
  const [anchor, setAnchor] = useState<HTMLElement>();

  const handleOutsideClick = useCallback(() => {}, []);

  const openMenu = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => setAnchor(e.currentTarget),
    [],
  );
  const navigate = useNavigateTo();

  const closeMenu = useCallback(() => setAnchor(undefined), []);

  return (
    <Box>
      <ClickAwayListener onClickAway={handleOutsideClick}>
        <Tooltip title="Select version" placement="right">
          <Button
            style={BUTTON_STYLE}
            color="neutral"
            variant="text"
            size="small"
            onClick={openMenu}
            endIcon={anchor ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            disabled={!selectedVersion}
          >
            <EllipsisText>{getElementName(selectedVersion)}</EllipsisText>
          </Button>
        </Tooltip>
      </ClickAwayListener>
      <Menu open={!!anchor} anchorEl={anchor} onClose={closeMenu}>
        {versions.map((version) => (
          <MenuItem
            value={version.id}
            key={'version-menu-item-' + version.id}
            onClick={() => {
              navigate({ versionId: version.id });
              closeMenu();
            }}
            sx={MENU_ITEM_STYLE}
            selected={version === selectedVersion}
          >
            {getElementName(version)}
            {versions.length > 1 && (
              <ListItemIcon style={{ justifyContent: 'flex-end' }}>
                <Clear
                  fontSize="small"
                  onClick={mouseEventHandler(
                    () => {
                      removeVersion(version.id);
                      closeMenu();
                    },
                    {
                      stopPropagation: true,
                    },
                  )}
                />
              </ListItemIcon>
            )}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem key="new-version" onClick={() => addVersion(selectedVersion)}>
          <ListItemIcon>
            <Add fontSize="small" />
          </ListItemIcon>
          New version
        </MenuItem>
      </Menu>
    </Box>
  );
};

const BUTTON_STYLE = {
  maxWidth: '100%',
  textTransform: 'none',
  minWidth: '100px',
  height: '32px',
} as const;

const MENU_ITEM_STYLE = {
  display: 'flex',
  justifyContent: 'space-between',
} as const;

export default VersionMenu;
