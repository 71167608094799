import React, {
  ChangeEventHandler,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { SelectProps, SxProps, TextField } from '@mui/material';
import { useIsReadonly } from '../../../../../hooks/user.hook';
import { Product } from '../../../../../../../shared/models/product.interface';
import { useIsKebabMenuOpen } from '../../../../kebab-menu/kebab-menu.hooks';

interface EPDSelectMenuProps {
  items: ReactNode[];
  epds: Product[];
  selectedEpdId?: string;
  epdKebabId: string;
  selectEPD: (epdId: string) => Promise<void>;
}

const EPDSelectMenu: React.FC<EPDSelectMenuProps> = ({
  items,
  epds,
  selectedEpdId,
  epdKebabId,
  selectEPD,
}) => {
  const readonly = useIsReadonly();
  const [isOpen, setIsOpen] = useState(false);
  const isKebabOpen = useIsKebabMenuOpen(epdKebabId);

  const selectProps = useMemo<SelectProps>(
    () => ({
      open: isOpen,
      onOpen: () => setIsOpen(true),
      onClose: () => setIsOpen(isKebabOpen ? true : false),

      renderValue: (value: unknown) =>
        epds.find(({ id }) => id === value)?.name,
    }),
    [epds, isKebabOpen, isOpen],
  );

  const textFieldStyles = useMemo<SxProps>(
    () => ({ mt: selectedEpdId ? 2 : 0 }),
    [selectedEpdId],
  );

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value } }) => {
      if (!isKebabOpen) {
        selectEPD(value);
      }
    },
    [isKebabOpen, selectEPD],
  );

  return (
    <TextField
      disabled={readonly}
      select
      fullWidth
      size="small"
      label="Select EPD"
      value={selectedEpdId}
      onChange={handleChange}
      SelectProps={selectProps}
      sx={textFieldStyles}
    >
      {...items}
    </TextField>
  );
};

export default EPDSelectMenu;
