import {
  ElementKind,
  IProductElement,
  IElement,
  IBuildingVersion,
  ElementKindMap,
  IID,
  OneOfChildElements,
  OneOfParentElements,
  OneOfElementListChildren,
} from '../models/project.interface';
import {
  FactoryCountExpression,
  IFactoryProductElement,
} from '../models/element_factory_helpers.interface';
import {
  Replace,
  RequireProperties,
  SemiPartial,
} from '../models/type_helpers.interface';
import * as uuid from 'uuid';
import { hasDefinedProperties, omitUndefined } from './object_helpers';
import {
  createCountAndUnit,
  createExpression,
} from './expression_factory_helpers';
import { IFactoryProperty } from '../models/element_property.interface';
import { createElementProperties } from './element_property_factory_helpers';
import { createElementQuantityRecord } from './element_quantity_helpers';
import { FactoryQuantityRecord } from '../models/element_quantities.interface';
import { validateElement } from '../validation/project.validation';
import { mainCategoryIds } from '../models/element_categories.interface';
import { getElementCategoryById } from '../templates/categories';
import {
  flattenElements,
  getChildElements,
  getElementKind,
  isElement,
} from './recursive_element_helpers';
import { asArray } from './array_helpers';
import {
  generateNewElementVersionIds,
  getElementVersionId,
} from './element-version.helpers';
import { createProposal } from './proposal.helpers';
export type CreatedElement<T extends keyof ElementKindMap> = ElementKindMap[T];

/**
 * Get existing it or create a new one
 * @param obj
 * @param regenerateId Force to regenerate id
 * @returns
 */
export const createId = (obj: Partial<IID>, regenerateId?: boolean): string =>
  regenerateId || !obj.id ? uuid.v4() : obj.id;

/**
 * Make sure id always exist
 * @param defaults
 * @param regenerateId
 * @returns
 */
export const applyId = <T extends Partial<IID>>(
  defaults: T,
  regenerateId: boolean,
): RequireProperties<T, 'id'> => {
  return {
    ...defaults,
    id: createId(defaults, regenerateId),
  };
};

/**
 * Create an array of elements from a partial array of elements
 * @param factoryElements
 * @param regenerateId If true it will regerate id for all elements even if they already exist
 * @returns
 */
export const createElements = <T extends OneOfFactoryElements>(
  factoryElements: T[] = [],
  regenerateId?: boolean,
): CreatedElement<T['kind']>[] =>
  generateNewElementVersionIds(
    factoryElements.map((e) => createElementFromPartial(e, regenerateId)),
    regenerateId,
  );

/**
 * Make sure all child element is created with correct default values
 * @param defaults
 * @param regenerateId
 * @returns
 */
const createChildElements = (
  defaults: Partial<IFactoryVersion | IFactoryElement>,
  regenerateId: boolean,
): OneOfChildElements[] => createElements(defaults.elements, regenerateId);

const createProductElement = (
  defaults: Partial<IFactoryProductElement>,
  regenerateId: boolean,
): IProductElement => {
  if (!defaults.product_id) {
    throw new Error('Product id is required');
  }

  return validateElement({
    kind: ElementKind.Product,
    product_id: '',
    unit: 'kg',
    ...omitUndefined(defaults),
    count: createExpression(
      typeof defaults.count !== 'boolean' && defaults.count
        ? defaults.count
        : 1,
    ),
    id: createId(defaults, regenerateId),
  });
};

const createVersion = (
  defaults: Partial<IFactoryVersion>,
  regenerateId: boolean,
): IBuildingVersion => {
  const mainCategoryElements = mainCategoryIds.map((id) =>
    createElementFromPartial({
      kind: ElementKind.Element,
      category_id: id,
      fallbackName: getElementCategoryById(id).name,
    }),
  );

  const elements = defaults?.elements?.length
    ? createChildElements(defaults, true)
    : mainCategoryElements;

  const idMap = getIdMap(defaults.elements, elements);

  const proposals = defaults.proposals?.length
    ? defaults.proposals.map((p) => createProposal(p, idMap))
    : [createProposal({ active: true }, idMap)];

  return {
    kind: ElementKind.Version,
    name: 'Version',
    products: {},
    properties: [],
    ...omitUndefined(defaults),
    proposals,
    id: createId(defaults, regenerateId),
    elements,
  };
};

export interface IElementVersionMap {
  versionMap: Map<IElement['versionId'], IElement['versionId']>;
  elementMap: Map<IElement['id'], IElement['id']>;
}

const getIdMap = (
  prev: OneOfChildFactoryElements[] = [],
  next: OneOfElementListChildren[] = [],
): IElementVersionMap | undefined => {
  const versionMap: Map<IElement['versionId'], IElement['versionId']> =
    new Map();
  const elementMap: Map<IElement['id'], IElement['id']> = new Map();

  const validRootElements = prev.filter(
    (e) => isElement(e) && hasDefinedProperties(e, 'id', 'unit', 'count'),
  ) as IElement[];

  const prevElements = flattenElements(...validRootElements);
  const nextElements = flattenElements(...next);

  // If a version is duplicated length should be the same to
  if (prevElements.length !== nextElements.length) {
    return;
  }

  for (let i = 0; i < prevElements.length; i++) {
    const prevElement = prevElements[i];
    const nextElement = nextElements[i];

    const prevVersionId = getElementVersionId(prevElement);
    const nextVersionId = getElementVersionId(nextElement);

    if (prevVersionId && prevElement && nextElement) {
      if (prevVersionId === nextVersionId || !nextVersionId) {
        throw new Error('Version ids not updated');
      }
      versionMap.set(prevVersionId, nextVersionId);
      elementMap.set(prevElement.id, nextElement.id);
    }
  }
  return versionMap.size || elementMap.size
    ? { versionMap, elementMap }
    : undefined;
};

export const createElement = (
  defaults: Partial<IFactoryElement>,
  regenerateId?: boolean,
): IElement => {
  // Default value for count, provide only if unit is passed
  const element: IElement = {
    kind: ElementKind.Element,
    name: '',
    ...omitUndefined(defaults),
    ...createCountAndUnit(defaults),
    id: createId(defaults, regenerateId),
    quantity: createElementQuantityRecord(defaults.quantity),
    count: createExpression(
      typeof defaults.count !== 'boolean' && defaults.count
        ? defaults.count
        : 1,
    ),
    properties: createElementProperties(defaults.properties),
    elements: createChildElements(defaults, true),
  };

  // Make sure element is valid before returning
  return element;
};

/**
 * Create a new element of a specific kind.
 * Can also be used to shallow clone an element.
 * @param kind Type of element which should be created
 * @param defaults A partial with overides of the default values
 * @param regenerateId If true this will always return element with a new id
 * @returns
 */
export function createElementOfType<T extends keyof ElementKindMap>(
  kind: T,
  defaults: Partial<FactoryKindMap[T]> = {},
  regenerateId = true,
): CreatedElement<T> {
  switch (kind) {
    case ElementKind.Version: {
      return createVersion(
        defaults as Partial<IFactoryVersion>,
        regenerateId,
      ) as CreatedElement<T>;
    }
    case ElementKind.Element: {
      return createElement(
        defaults as Partial<IFactoryElement>,
        regenerateId,
      ) as CreatedElement<T>;
    }
    case ElementKind.Product: {
      return createProductElement(
        defaults as Partial<IFactoryProductElement>,
        regenerateId,
      ) as CreatedElement<T>;
    }
  }
  throw new Error(`Can't create element of type ${kind}`);
}

/**
 * Create a new element of a specific kind.
 * Can also be used to shallow clone an element.
 * @param initialValues A partial with overides of the default values. Need to contain kind
 * @param regenerateId If true this will always return element with a new id
 * @returns
 */
export function createElementFromPartial<T extends OneOfFactoryElements>(
  initialValues: T,
  regenerateId = true,
): CreatedElement<T['kind']> {
  return createElementOfType(
    initialValues.kind,
    initialValues,
    regenerateId,
  ) as CreatedElement<T['kind']>;
}

export type OneOfChildFactoryElements =
  | IFactoryElement
  | IFactoryProductElement;

export type IFactoryElement = SemiPartial<
  Replace<
    IElement,
    {
      elements: OneOfChildFactoryElements[];
      properties: IFactoryProperty[];
      count: FactoryCountExpression;
      quantity: FactoryQuantityRecord;
    }
  >,
  'kind'
>;

/**
 * Add element to parent
 * @param parent
 * @param initialValues One or more elements to be added
 * @returns The modified parent
 */
export const addElementsToParent = <R extends OneOfParentElements>(
  parent: OneOfParentElements,
  initialValues: OneOfFactoryElements | OneOfFactoryElements[],
  regenerateId = true,
): R => {
  const elements = getChildElements(parent);
  const newElements = createElements(asArray(initialValues), regenerateId);
  return { ...parent, elements: [...elements, ...newElements] } as R;
};

export type IFactoryVersion = SemiPartial<
  Replace<
    IBuildingVersion,
    {
      elements: OneOfChildFactoryElements[];
    }
  >,
  'kind'
>;

export type OneOfFactoryElements = IFactoryVersion | OneOfChildFactoryElements;

/**
 * Use FactoryKindMap to get correct type of elements
 */
type FactoryKindMap = {
  [ElementKind.Product]: IFactoryProductElement;
  [ElementKind.Element]: IFactoryElement;
  [ElementKind.Version]: IFactoryVersion;
};

export const isFactoryProductElement = (
  element?: OneOfFactoryElements,
): element is IFactoryProductElement => {
  return getElementKind(element) === ElementKind.Product;
};

export const isFactoryVersion = (
  element?: OneOfFactoryElements,
): element is IFactoryVersion => {
  return getElementKind(element) === ElementKind.Version;
};

export const isFactoryElement = (
  element?: OneOfFactoryElements,
): element is IFactoryElement => {
  return getElementKind(element) === ElementKind.Element;
};

export const isOneOfChildFactoryElements = (
  element?: OneOfFactoryElements,
): element is IFactoryProductElement | IFactoryElement => {
  return isFactoryElement(element) || isFactoryProductElement(element);
};
