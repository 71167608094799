import { useCallback, useEffect, useRef } from 'react';
import { OneOfProjectListElements } from '../../../shared/models/project.interface';
import { createFilterStore } from './filter.hook';
import { useFolderGFA, useProjectFolderTree } from '../store/folder';
import { isProjectInfo } from '../../../shared/helpers/project-folder.helpers';
import { createLocalStorageStore } from '../helpers/local-storage.helpers';
import { SortByAlternativeKey } from '../../../shared/helpers/tree.helpers';
import { useUserLookup } from './user.hook';
import { useUIState } from '../store/ui';

export const { useStore: useSortProjectsBy, set: setSortProjectsBy } =
  createLocalStorageStore<SortByAlternativeKey<OneOfProjectListElements>>(
    'sort_projects_by',
    'name',
  );

export const {
  setItems,
  setSortingOption,
  useFilteredChildren: useSortedProjects,
} = createFilterStore<OneOfProjectListElements>({
  childrenKey: 'elements',
  devtoolsName: 'ProjectListSorting',
  sorting: [
    {
      id: 'projects-folder-sort',
      property: 'kind',
      direction: 'asc',
    },
  ],
});

export const useInitProjectsSorting = (userId?: string) => {
  const sortFn = useSortFn();
  const tree = useProjectFolderTree(userId);
  const sortBy = useSortProjectsBy();

  useEffect(() => {
    setItems(tree);

    setSortingOption({
      id: 'projects-sort',
      direction: ['owner', 'name'].includes(sortBy as string) ? 'asc' : 'desc',
      property: sortFn,
    });
  }, [sortBy, sortFn, tree]);
};

const useSortFn = () => {
  const getFolderGFA = useFolderGFA();
  const users = useUserLookup();
  const sortBy = useSortProjectsBy();
  const { addedElementId } = useUIState('addedElementId');

  const sortRef =
    useRef<SortByAlternativeKey<OneOfProjectListElements>>(sortBy);

  const itemRef = useRef('');

  return useCallback(
    (item: OneOfProjectListElements) => {
      // place new element at the top of the list
      if (sortRef.current === sortBy && itemRef.current === item.id) {
        return sortBy === 'gfa' ? undefined : true;
      }
      if (addedElementId) {
        itemRef.current = addedElementId;
      }
      if (sortRef.current !== sortBy) {
        sortRef.current = sortBy;
        itemRef.current = '';
      }

      switch (sortBy) {
        case 'updated_at': {
          return item.updated_at;
        }
        case 'name': {
          return item.name.toLowerCase();
        }
        case 'gfa': {
          return isProjectInfo(item) ? item.gfa : getFolderGFA(item);
        }
        case 'owner': {
          if (isProjectInfo(item)) {
            const { owner: id } = item;
            return users[id]?.name;
          }
          break;
        }
        default:
          return '';
      }
    },
    [addedElementId, getFolderGFA, sortBy, users],
  );
};
