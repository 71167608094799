import { useCallback, useMemo } from 'react';
import {
  IElement,
  IElementID,
  OneOfElements,
  OneOfListElements,
  OneOfParentElements,
  Project,
} from '../../../shared/models/project.interface';
import {
  getProject,
  useUpdateElements,
  useUpdateProject,
} from '../store/project';
import {
  addElementVersion,
  setActiveElementVersion,
  getElementVersionId,
  ElementOrVersionId,
  getElementVersions,
} from '../../../shared/helpers/element-version.helpers';
import { getSelectedVersion, useSelectedVersionId } from '../store/ui';
import {
  getParentElement,
  getPathAndElementById,
  isElement,
} from '../../../shared/helpers/recursive_element_helpers';
import { ItemOrItemId } from '../../../shared/models/type_helpers.interface';
import { createLocalStorageStore } from '../helpers/local-storage.helpers';
import { useSortedFlattenedElements } from './filter-elements.hook';
import { required } from '../../../shared/helpers/function_helpers';
import {
  addElementVersionToProposal,
  getActiveProposal,
  isElementActiveInProposal,
} from '../../../shared/helpers/proposal.helpers';
import { last } from 'lodash';
import {
  getVersionById,
  updateElements,
} from '../../../shared/helpers/project_helpers';

const { useStore: useExpandedVersionIds, set: setExpandedElementVersionIds } =
  createLocalStorageStore<IElementID[]>('expanded_element_versions', []);

const {
  useStore: useBuildingVersionsWithExpandedElementVersions,
  set: setBuildingVersionsWithExpandedElementVersions,
} = createLocalStorageStore<IElementID[]>(
  'expanded_versions_with_element_versions',
  [],
);

const getParent = (
  elementOrId: ItemOrItemId<OneOfElements>,
): OneOfParentElements => {
  const selectedVersion = getSelectedVersion(true);
  const parent = getParentElement(selectedVersion, elementOrId);

  if (!parent) {
    throw new Error('No parent element found, could not add element version');
  }
  return parent;
};

/**
 * Get all element versions related to provided element in order of appearance in list
 * @param element
 * @returns
 */
export const useElementVersions = (element: OneOfListElements): IElement[] => {
  const elements = useSortedFlattenedElements();

  return useMemo(() => {
    const versionId = getElementVersionId(element);

    // Only IElement can have versions
    if (!isElement(element)) {
      return [];
    }
    // If no version id treat the element as the only version
    if (!versionId) {
      return [element];
    }
    return elements
      .filter((el) => getElementVersionId(el) === versionId)
      .filter(isElement); // Just for typing...
  }, [element, elements]);
};

export const useAddElementVersion = () => {
  // const updateElements = useUpdateElements();
  const updateProject = useUpdateProject();

  return useCallback(
    (original: IElement): Promise<Project> => {
      let project = getProject();
      const version = getSelectedVersion(true);
      const versionId = version.id;

      const getVersion = () => getVersionById(project, versionId);

      // Add the new element version to the parent and update the project
      project = updateElements(
        project,
        addElementVersion(
          required(getParentElement(version, original)),
          original,
        ),
      );

      // Get new modified elements
      const modifiedSearch = getPathAndElementById(
        getVersion(),
        original.id,
        true,
      );

      // Newly added element will by the last in the path
      const addedElement = required(
        last(getElementVersions(modifiedSearch.parent, modifiedSearch.element)),
      );

      // Update selections in proposals
      project = updateElements(
        project,
        addElementVersionToProposal(getVersion(), addedElement),
      );
      const activeProposal = getActiveProposal(getVersion());

      // This is expensive so only do it if we have an active proposal
      const addedSearch = activeProposal
        ? getPathAndElementById(getVersion(), addedElement.id, true)
        : undefined;

      // Activate the new element if it is active in the proposal
      if (
        addedSearch &&
        activeProposal &&
        isElementActiveInProposal(
          activeProposal,
          addedSearch.path,
          addedSearch.element,
        )
      ) {
        project = updateElements(
          project,
          setActiveElementVersion(addedSearch.parent, addedElement.id),
        );
      }

      return updateProject(project);
    },
    [updateProject],
  );
};

export const useSetActiveElementVersion = () => {
  const updateElements = useUpdateElements();

  return useCallback(
    async (elementOrId: ItemOrItemId<OneOfElements>) => {
      const parent = getParent(elementOrId);
      const modifiedParent = setActiveElementVersion(parent, elementOrId);

      return updateElements(modifiedParent as IElement);
    },
    [updateElements],
  );
};

/**
 * Check if an element version is expanded or not
 * @param elementOrVersionId
 * @returns
 */
export const useIsExpandedElementVersion = (
  elementOrVersionId?: ElementOrVersionId,
): boolean => {
  const versionId = getElementVersionId(elementOrVersionId);

  const expandedElementVersions = useExpandedVersionIds();

  return versionId ? !!expandedElementVersions?.includes(versionId) : false;
};

export const useHasExpandedElementVersions = () => {
  const expandedBuildingVersions =
    useBuildingVersionsWithExpandedElementVersions();
  const selectedVersionId = useSelectedVersionId();
  return (
    !!selectedVersionId &&
    !!expandedBuildingVersions?.includes(selectedVersionId)
  );
};

export const useToggleElementVersion = (
  elementOrVersionId?: ElementOrVersionId,
) => {
  const isExpanded = useIsExpandedElementVersion(elementOrVersionId);
  const collapse = useCollapseElementVersion();
  const expand = useExpandElementVersion();

  return useCallback(
    () =>
      isExpanded ? collapse(elementOrVersionId) : expand(elementOrVersionId),

    [collapse, elementOrVersionId, expand, isExpanded],
  );
};

export const useExpandElementVersion = () => {
  const expandedElementVersions = useExpandedVersionIds();
  const expandedBuildingVersions =
    useBuildingVersionsWithExpandedElementVersions();

  return useCallback(
    (elementOrVersionId: ElementOrVersionId) => {
      const versionId = getElementVersionId(elementOrVersionId);
      if (versionId && !expandedElementVersions?.includes(versionId)) {
        setExpandedElementVersionIds([
          ...(expandedElementVersions ?? []),
          versionId,
        ]);
      }
      const selectedVersionId = getSelectedVersion(true)?.id;

      if (
        selectedVersionId &&
        !expandedBuildingVersions?.includes(selectedVersionId)
      ) {
        setBuildingVersionsWithExpandedElementVersions([
          ...(expandedBuildingVersions ?? []),
          selectedVersionId,
        ]);
      }
    },
    [expandedBuildingVersions, expandedElementVersions],
  );
};

export const useCollapseElementVersion = () => {
  const expandedElementVersions = useExpandedVersionIds();
  const expandedBuildingVersions =
    useBuildingVersionsWithExpandedElementVersions();

  return useCallback(
    (elementOrVersionId: ElementOrVersionId) => {
      const versionId = getElementVersionId(elementOrVersionId);

      if (versionId && expandedElementVersions?.includes(versionId)) {
        setExpandedElementVersionIds(
          expandedElementVersions.filter((id) => id !== versionId),
        );
      }

      const selectedVersionId = getSelectedVersion(true)?.id;
      if (
        selectedVersionId &&
        expandedBuildingVersions?.includes(selectedVersionId)
      ) {
        setBuildingVersionsWithExpandedElementVersions(
          expandedBuildingVersions.filter((id) => id !== selectedVersionId),
        );
      }
    },
    [expandedBuildingVersions, expandedElementVersions],
  );
};

export const useIsFirstElementVersionInList = (element: IElement): boolean => {
  const versions = useElementVersions(element);
  return versions[0]?.id === element.id;
};
