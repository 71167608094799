import { ModelTimestamps } from './base.interface';
import { QuantityUnit, ConversionFactors, Results } from './unit.interface';
import { IProductElement } from './project.interface';
import { Replace, SemiPartial } from '../models/type_helpers.interface';
import { Characteristics } from './product-characteristics.interface';
import { ElementCategoryID } from './element_categories.interface';
import { PropertyCountValueRecord } from './element_property.interface';

export const BOVERKET_ID_PREFIX = 'boverket_sv-SE_';
export const NODON_ID_PREFIX = 'nodon_';
export const CUSTOM_ID_PREFIX = 'custom_';
export const OKOBAUDAT_ID_PREFIX = 'ökobaudat_';

export interface IAnyBoolean {
  [key: string]: boolean;
}

export interface ProductCategories {
  Boverket?: IAnyBoolean;
  BK04?: IAnyBoolean;
  ILCD?: IAnyBoolean;
  Custom?: boolean;
  Library?: boolean;
}

export type ProductID = string;
export type ProductRecord = Record<ProductID, Product>;
export type ProductSources = 'Boverket' | 'custom' | 'ökobaudat' | 'Nodon';

/**
 * This is the product imported from Boverket, BK04 (ökobaudat) or created by the user.
 * Contains what's needed to calculate c02 emission based on a quantity.
 * Not to be confused with ProductElement
 */
export interface Product extends ModelTimestamps {
  id: ProductID;
  name: string;
  owner?: string;

  /**
   * Organizations that can access this product.
   * Undefined means it's a public product and all organizations can access it.
   */
  organizations?: string[];
  unit: QuantityUnit;
  description?: string;

  source: ProductSources;
  categories: ProductCategories;
  generic_id?: string;
  external_identifiers: Record<string, string>;

  /**
   * Building life-cycle stages cheatsheet
   *
   * A1-A3: Production
   *
   * A4: Transport
   *
   * A5: Installation / waste
   * */
  conversion_factors: ConversionFactors;

  /**
   * Material characteristics like thermal conductivity and heat capacity
   */
  characteristics: Characteristics;

  /**
   * The ID of the element category this product belongs to (optional).
   */
  category_id?: ElementCategoryID;

  /**
   * A snapshot of the properties of the element category at the time of creation.
   * Can be used to filter recipes based on the properties of the element category.
   */
  category_property_value_record: PropertyCountValueRecord;
}

export interface IProductElementArticle extends IProductElement {
  path: string[];
  quantity: number;
  co2e: number;
  cost: number;
}

export interface IProductElementParentItem {
  productId: ProductID;
  name: string;
  unit: QuantityUnit;
  quantitiesSum: number;
  co2eSum: number;
  costSum: number;
  sums: Results;
  articles: IProductElementArticle[];
  disableProductSwitch: boolean;
}

/**
 * Raw product from DB
 */
export type ProductJSON = Omit<
  Product,
  | 'created_at'
  | 'updated_at'
  | 'deleted_at'
  | 'description'
  | 'owner'
  | 'organizations'
> & {
  created_at: string;
  updated_at: string;
  deleted_at?: string | null;
  description?: string | null;
  owner?: string | null;
  organizations?: string[] | null;
  generic_id?: string | null;
};

type IFactoryProductBase = SemiPartial<Product, 'id' | 'name'>;

type IFactoryBoverketProduct = Replace<
  IFactoryProductBase,
  {
    source?: Extract<Product['source'], 'Boverket'>;
  }
>;

type IFactoryNodonProduct = Replace<
  IFactoryProductBase,
  {
    source?: Extract<Product['source'], 'Nodon'>;
  }
>;

type IFactoryOekobaudatProduct = Replace<
  IFactoryProductBase,
  {
    source?: Extract<Product['source'], 'ökobaudat'>;
  }
>;

export type IFactoryCustomProduct = Replace<
  IFactoryProductBase,
  {
    id?: Product['id'];
    source?: Extract<Product['source'], 'custom'>;
    organizations: NonNullable<Product['organizations']>;
  }
>;

export type IFactoryProduct =
  | IFactoryCustomProduct
  | IFactoryBoverketProduct
  | IFactoryNodonProduct
  | IFactoryOekobaudatProduct;

export const REQUIRED_PRODUCT_PROPERTIES: Array<keyof Product> = [
  'categories',
  'characteristics',
  'conversion_factors',
  'created_at',
  'id',
  'name',
  'source',
  'unit',
  'updated_at',
];
