import { useCallback, useMemo } from 'react';
import {
  IBuildingVersion,
  IElement,
  OneOfElementListElements,
  OneOfElements,
} from '../../../shared/models/project.interface';
import { getSelectedVersion, useSelectedVersionProducts } from '../store/ui';
import {
  generateFallbackName as generateFallbackName,
  getFallbackName,
} from '../../../shared/helpers/project_helpers';
import { useUpdateElements } from '../store/project';
import { getElementCategory } from '../../../shared/helpers/element_category_helpers';
import { SemiPartial } from '../../../shared/models/type_helpers.interface';
import { isBuildingVersionElement } from '../../../shared/helpers/recursive_element_helpers';
import {
  getElementCategoryId,
  getElementName,
} from '../../../shared/helpers/element_helpers';
import amplitudeLog from '../amplitude';
import { OneOfFactoryElements } from '../../../shared/helpers/element_factory_helpers';
import { getRecipeId } from '../../../shared/helpers/recipe_helpers';
import { getRecipeLookup } from '../store/recipe/recipe.hook';

type ElementFallbackName = (
  /**
   * Name to set on the elements.
   * undefined means that the fallback should be regenerated if it exists.
   */
  name: string | undefined,
  ...elements: (IElement | IBuildingVersion)[]
) => Promise<void>;

/**
 * Set name on one or more elements.
 * Pass undefined as name to regenerate fallback name.
 * @returns
 */
export const useSetNameOfElements = (): ElementFallbackName => {
  const updateElements = useUpdateElements();
  const generateFallbackName = useGenerateFallbackName();

  return useCallback(
    async (name, ...elements) => {
      if (!elements.length) {
        return;
      }

      const updates: (
        | SemiPartial<OneOfElementListElements, 'id'>
        | undefined
      )[] = elements.map((element) => {
        const id = element.id;

        // Building version elements should only have their name set
        if (isBuildingVersionElement(element)) {
          if (!name) {
            throw new Error('Cannot set empty name on building version');
          }
          return { id, name };
        }

        // Update name and remove fallback name
        return {
          id: element.id,
          name: name ?? '',
          fallbackName: generateFallbackName(element),
        };
      });

      await updateElements(...updates);

      for (const element of elements) {
        amplitudeLog('Element Name Set', {
          Name: name,
          ElementID: element.id,
        });
      }
    },
    [generateFallbackName, updateElements],
  );
};

export const useElementName = (
  element: OneOfElements | undefined,
  defaultName = 'Unknown',
): string => {
  const products = useSelectedVersionProducts();

  return useMemo(
    () => getElementName(element, products, defaultName),
    [defaultName, element, products],
  );
};

/**
 * Get a function that generates a new fallback name.
 * @returns
 */
export const useGenerateFallbackName = () => {
  return useCallback(
    (element?: OneOfElements | OneOfFactoryElements): string => {
      const version = getSelectedVersion(true);

      if (!element) {
        return generateFallbackName(version) ?? '';
      }

      const recipeLookup = getRecipeLookup();
      const recipeId = getRecipeId(element);
      const recipeName = recipeId ? recipeLookup[recipeId]?.name : undefined;

      const categoryId = getElementCategoryId(element);
      const categoryName = categoryId
        ? (getElementCategory(categoryId)?.name ?? 'None')
        : undefined;

      const baseName = recipeName ?? categoryName ?? 'Element';
      const currentFallback = getFallbackName(element);

      // Keep current if it has not been changed
      if (currentFallback?.startsWith(baseName)) {
        return currentFallback;
      }

      return generateFallbackName(version, baseName + ' 1') ?? '';
    },
    [],
  );
};

/**
 * Apply a new fallback name to an element without updating on the server.
 * @returns
 */
export const useApplyFallbackName = () => {
  const generateFallbackName = useGenerateFallbackName();

  return useCallback(
    (element: IElement): IElement => {
      const fallbackName = generateFallbackName(element);

      // Only update if the fallback name has changed
      return getFallbackName(element) !== fallbackName
        ? { ...element, fallbackName }
        : element;
    },
    [generateFallbackName],
  );
};
