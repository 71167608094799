import { ElementCategoryID } from '../../../../shared/models/element_categories.interface';
import { ElementPropertyID } from '../../../../shared/models/element_property.interface';
import {
  IBuildingVersion,
  OneOfSelectableElements,
  IElementID,
} from '../../../../shared/models/project.interface';
import { SelectableQuantityUnit } from '../../../../shared/models/unit.interface';

export const pages = ['elements', 'products', 'calculations'] as const;
export type Page = (typeof pages)[number];

export type ExpressionUpdateFn = ({
  localValue,
  localUnit,
  shouldNotSetErrorMessage,
  shouldNotUpdateBackend,
}: {
  localValue: string;
  localUnit: SelectableQuantityUnit | undefined;
  shouldNotSetErrorMessage?: boolean;
  shouldNotUpdateBackend?: boolean;
}) => void;

type ClearSelectionExcludeType =
  | 'addedElementId'
  | 'addedPropertyId'
  | 'selectedElementCategoryId'
  | 'selectedElement'
  | 'showProjectDetailsEditor'
  | 'expressionInputPanelError';

type ContextName =
  | 'productSelectorOpen'
  | 'projectSelectorOpen'
  | 'expressionPanelOpen'
  | 'inputFocused'
  | 'disableGlobalKeyListener';

export type AppContext = Record<ContextName, boolean>;

export interface IUIState {
  showProjectDetailsEditor: boolean;
  setShowProjectDetailsEditor: (value: boolean) => void;

  showProjectSelector: boolean;
  setShowProjectSelector: (showProjectSelector: boolean) => void;

  isProjectSelectorActionMenuOpen: boolean;
  setIsProjectSelectorActionMenuOpen: (
    isProjectSelectorActionMenuOpen: boolean,
  ) => void;

  geoSettingsShouldDirty: boolean;
  setGeoSettingsShouldDirty: (geoSettingsShouldDirty: boolean) => void;

  appContext: AppContext;
  setAppContext: (context: AppContext) => void;

  appHeaderOffset: number;
  setAppHeaderOffset: (offset: number) => void;

  selectedPage: Page;
  setSelectedPage: (page: Page) => void;

  observedElementIds: Record<IElementID, boolean>;
  addObservedElementIds: (
    observedElementIds: Record<IElementID, boolean>,
  ) => void;

  addedElementPlacement?: 'top' | 'bottom' | 'keep';
  setAddedElementPlacement: (placement?: 'top' | 'bottom' | 'keep') => void;

  addedElementId?: string;
  setAddedElementId: (addedElementId?: string) => void;

  addedPropertyId?: string;
  setAddedPropertyId: (addedPropertyId?: string) => void;

  /** The selected main category element */
  selectedElementCategoryId?: ElementCategoryID;
  setSelectedElementCategoryId: (
    selectedElementCategoryId?: ElementCategoryID,
  ) => void;

  selectedElementPropertyId?: string;
  setSelectedElementPropertyId: (
    selectedElementPropertyId?: ElementPropertyID,
  ) => void;

  selectedElement?: OneOfSelectableElements;
  setSelectedElement: (id?: string) => void;

  selectedVersion?: IBuildingVersion;
  setSelectedVersion: (id?: string) => void;

  recentlyUndoRedoElementId?: IElementID;
  setRecentlyUndoRedoElementId: (id?: IElementID) => void;

  expressionInputPanelError?: string;
  setExpressionInputPanelError: (error: string | undefined) => void;

  renamingId?: string;
  setRenamingId: (id?: string) => void;

  selectedProjectFolderId?: string;
  setSelectedProjectFolderId: (id?: string) => void;

  isExpressionInputPanelOpen?: boolean;
  setIsExpressionInputPanelOpen: (isOpen: boolean) => void;

  clearSelections: (...exclude: ClearSelectionExcludeType[]) => void;
}
