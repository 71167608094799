import { useCallback, useMemo } from 'react';
import {
  applyRecipe,
  applyRecipeById,
  getApplicableRecipes,
  getRecipeConversionFactorRecord,
  getRecipeId,
  isAutoRecipeId,
  isNotInSortedRecipes,
  isRecipe,
  isRecipeAndElementEqual,
  isRecipeCategoryPropertyValueRecordModified,
  overwriteRecipe,
  resolveAutoRecipeId,
  sortRecipesByCO2eTotalRecord,
  updateRecipeFromElement,
} from '../../../shared/helpers/recipe_helpers';
import { Results } from '../../../shared/models/unit.interface';
import { Recipe, RecipeID } from '../../../shared/models/recipe.interface';
import {
  IElement,
  OneOfElements,
} from '../../../shared/models/project.interface';
import {
  getCO2eTotalRecord,
  getElementConversionFactorSum,
} from '../../../shared/helpers/results.helpers';
import {
  getAllBuildingVersions,
  getAllRecipeElements,
  getElementById,
  hasChildren,
  isElement,
} from '../../../shared/helpers/recursive_element_helpers';
import { useSelectedVersion, useSelectedVersionId } from '../store/ui';
import { getProductsLookup, useProductsLookup } from '../store/product';
import { useSharedMemo } from './hooks';
import {
  useRecipeLookup,
  useRecipes,
  getRecipeLookup,
  useUpdateRecipe,
  getRecipes,
} from '../store/recipe/recipe.hook';
import { ItemOrItemId } from '../../../shared/models/type_helpers.interface';
import { applyChanges, getId } from '../../../shared/helpers/object_helpers';
import { getProject, useProject, useUpdateElements } from '../store/project';
import { useIsConfirmed } from './confirm.hook';
import { getExpressionVariablesRecord } from '../../../shared/helpers/expression_variables_helpers';
import {
  enrichElementStructure,
  getVersionById,
  updateElements,
} from '../../../shared/helpers/project_helpers';
import { updateElementPropertiesFromVariablesRecord } from '../../../shared/helpers/element_property_helpers';
import { resolveCountExpressionsInVersions } from '../../../shared/helpers/expression_solving_helpers';
import { expandElements } from '../hooks/expand-elements.hook';
import { useApplyFallbackName } from './element-name.hook';

/**
 * Heavy but exact calculation of the CO2 impact of a recipe.
 * TODO: Optimize this somehow.
 * @param element
 * @param recipeId
 * @returns
 */
export const useRecipeConversionFactors = (
  element: IElement,
  recipeId: RecipeID | undefined = element.recipe_id,
): Results => {
  // Support auto recipe ids
  recipeId = getRecipeId(recipeId);

  let project = getProject();
  const productLookup = getProductsLookup();
  const recipes = useRecipeLookup();
  const versionId = useSelectedVersionId();
  const elementId = element.id;
  const recipe = recipeId && recipes[recipeId];

  if (!versionId) {
    throw new Error('No version id');
  }

  if (!recipe) {
    throw new Error('No recipe found');
  }

  return useSharedMemo(
    (): Results => {
      const elementWithRecipe = applyRecipe(element, recipe);
      const tempVersionId = 'temp:' + versionId;

      // Shortcuts to get latest version & element
      const getVersion = () => getVersionById(project, tempVersionId, true);
      const getElement = () => getElementById(project, elementId) as IElement;

      if (elementWithRecipe !== element) {
        // To not invalidate caches, use a temporary versionId
        const versions = getAllBuildingVersions(project).map((v) =>
          v.id === versionId
            ? {
                ...v,
                id: tempVersionId,
                // Make all product available in the version
                products: { ...productLookup, ...v.products },
              }
            : v,
        );
        project = applyChanges(project, { buildings: [{ versions }] });

        // Apply new element with recipe to project tree
        project = updateElements(project, elementWithRecipe);

        // Recalculate category variables, auto-values etc
        project = updateElements(
          project,
          enrichElementStructure(
            getElement(),
            [],
            getExpressionVariablesRecord(project, tempVersionId, getElement()),
            getProductsLookup(),
          ),
        );

        // Get latest variable values
        const variables = getExpressionVariablesRecord(
          project,
          tempVersionId,
          getElement(),
        );

        // Save the new variable values to the project
        project = updateElementPropertiesFromVariablesRecord(
          project,
          tempVersionId,
          variables,
        );

        // Recalculate again category variables, auto-values etc
        project = updateElements(
          project,
          enrichElementStructure(
            getElement(),
            [],
            getExpressionVariablesRecord(project, tempVersionId, getElement()),
          ),
        );

        // 3. Update all "count" values in the version
        project = resolveCountExpressionsInVersions(
          project,
          variables,
          getVersionById(project, tempVersionId), // Version might be overwritten so fetch latest
        );
      }

      // Get CO2, kg etc
      return getElementConversionFactorSum(
        getVersion(),
        getElementById(getVersion(), elementId) as IElement,
      );
    },
    `useRecipeConversionFactors[${elementId}, ${recipeId}]`,
    [element, recipe],
  );

  // TODO: Break out everything above into separate hook
};

/**
 * Get an up-to-date ConversionFactor record for the available receipes
 */
const useRecipeConversionFactorsRecord = (): Record<RecipeID, Results> => {
  const recipes = useRecipes();
  const productsLookup = useProductsLookup();
  const selectedVersion = useSelectedVersion();

  return useSharedMemo(
    () =>
      selectedVersion
        ? getRecipeConversionFactorRecord(
            recipes,
            productsLookup,
            selectedVersion,
          )
        : {},
    'useRecipeConversionFactorsRecord',
    [productsLookup, recipes, selectedVersion?.products],
  );
};

export const useRecipeCO2eRecord = (): Record<RecipeID, number> => {
  const record = useRecipeConversionFactorsRecord();
  return useSharedMemo(
    () => getCO2eTotalRecord(record),
    'useRecipeCO2eRecord',
    [record],
  );
};

/**
 *
 * @param element
 * @param gfa
 * @returns
 */
// export const useRecipeCO2eTotalRecord = (
//   element: IElement,
//   co2PerGFA?: boolean,
// ): Record<RecipeID, number> => {
//   const record = useRecipeCO2eRecord();
//   const quantity = useElementTotalCount(element);
//   const gfa = useBuildingGFA();
//   const quantityPerGfa = quantity / (co2PerGFA ? gfa : 1) || 0;

//   return useMemo(
//     () => mapFilterRecord(record, (item) => item * quantityPerGfa),
//     [quantityPerGfa, record],
//   );
// };

/**
 * Get a sorted array of recipes based on their co2 impact (lowest to highest)
 */
export const useSortedRecipes = (): Recipe[] => {
  const recipes = useRecipes();
  const sums = useRecipeCO2eRecord();

  console.warn("Don't use this until auto is fixed");

  return useSharedMemo(
    () => sortRecipesByCO2eTotalRecord(recipes, sums),
    'useSortedRecipes',
    [recipes, sums],
  );
};

/**
 * When a newly created recipe is referenced it won't exist in sortedRecipes yet
 *
 * @returns the sorted recipes or an updated list of the recipes if the recipe is not among the sorted recipes
 */
export const useRefreshRecipes = (): ((
  recipeId: string | undefined,
) => Recipe[]) => {
  const sortedRecipes = useRecipes();

  return useCallback(
    (recipeId: string | undefined) =>
      isNotInSortedRecipes(recipeId, sortedRecipes)
        ? getRecipes()
        : sortedRecipes,
    [sortedRecipes],
  );
};

/**
 * The recipe that should be applied if the user selects auto for the given element
 * @param element
 * @returns
 */
export const useSuggestedAutoRecipe = (element: OneOfElements | undefined) => {
  const recipe = useApplicableRecipes(element)[0];
  return recipe;
};

/**
 * Get the recipe currently used by the element (by auto or manual selection)
 * @param element
 * @returns
 */
export const useRecipe = (
  element: OneOfElements | undefined,
): Recipe | undefined => {
  const id = resolveAutoRecipeId(element);
  const lookup = useRecipeLookup();
  const auto = useSuggestedAutoRecipe(element);
  return useMemo(() => {
    if (id && lookup[id]) {
      return lookup[id];
    }
    return isAutoRecipeId(element) ? auto : undefined;
  }, [element, id, lookup, auto]);
};

export const useIsRecipeModified = (element?: OneOfElements): boolean => {
  const recipe = useRecipe(element);

  return useMemo(
    () =>
      recipe && isElement(element)
        ? !isRecipeAndElementEqual(recipe, element)
        : false,
    [element, recipe],
  );
};

export const useIsRecipeCategoryPropertyValueRecordModified = (
  element?: OneOfElements,
): boolean => {
  const recipe = useRecipe(element);

  return useMemo(
    () =>
      recipe && isElement(element)
        ? !isRecipeCategoryPropertyValueRecordModified(element, recipe)
        : false,
    [element, recipe],
  );
};

/**
 * Return all applicable recipes for the given element ordered by co2
 * @param element
 * @param groupUnmappedRecipesInOther If true all recipes that can't be mapped to a enabled category will be grouped in the "Other" category
 * @returns
 */
export const useApplicableRecipes = (
  element?: OneOfElements,
  groupUnmappedRecipesInOther = false,
): Recipe[] => {
  const recipes = useRecipes();

  return useMemo(
    () => getApplicableRecipes(recipes, element, groupUnmappedRecipesInOther),
    [recipes, element, groupUnmappedRecipesInOther],
  );
};

export const useApplyRecipe = (): ((
  element: IElement,
  recipeOrId?: ItemOrItemId<Recipe>,
  showConfirm?: boolean,
) => Promise<IElement | undefined>) => {
  const confirm = useIsConfirmed();
  const updateElements = useUpdateElements();
  const refreshRecipes = useRefreshRecipes();
  const applyFallbackName = useApplyFallbackName();

  return useCallback(
    async (
      element: IElement,
      recipeOrId?: ItemOrItemId<Recipe>,
      showConfirm = true,
    ) => {
      const id = recipeOrId ? getId(recipeOrId) : getRecipeId(element);
      const recipes = refreshRecipes(id);

      const oldRecipeName =
        recipes.find((recipe) => element.recipe_id?.includes(recipe.id))
          ?.name ?? 'None';

      const recipe = isRecipe(recipeOrId)
        ? recipeOrId
        : recipes.find(
            (recipe) =>
              recipe.id === recipeOrId ||
              element.recipe_id?.includes(recipe.id),
          );

      /* If specific conditions are met, show a confirmation dialog to discard element's current content.
      Retrieve the name of the element, or falls back to the fallbackName if the name is not available. */
      if (
        showConfirm &&
        recipeOrId !== 'detach' &&
        hasChildren(element) &&
        (isRecipeModified(element) ||
          (!hasRecipe(element) && recipeHasNewContent(element, recipe))) &&
        !(await confirm({
          title: 'Discard changes?',
          description: `Unsaved changes on recipe "${oldRecipeName}" will be lost. This action cannot be undone.`,
          confirmationText: 'Discard changes',
          allowClose: false,
        }))
      ) {
        return;
      }

      // First apply the recipe to the element, then apply a new fallback name
      const updatedElement = applyFallbackName(
        applyRecipeById(recipes, element, id),
      );

      if (updatedElement.elements.length) {
        expandElements(updatedElement);
      }

      // Element has been changed => Show confirm dialog to approve and save
      if (updatedElement !== element) {
        await updateElements(updatedElement);
      }

      return updatedElement;
    },
    [applyFallbackName, confirm, refreshRecipes, updateElements],
  );
};

export const useResetRecipe = (): ((
  element: IElement,
  recipe: Recipe,
) => Promise<void>) => {
  const confirm = useIsConfirmed();
  const updateElements = useUpdateElements();

  return useCallback(
    async (element: IElement, recipe: Recipe) => {
      if (
        await confirm({
          title: 'Discard changes?',
          description: `Unsaved changes on recipe "${recipe.name}" will be lost. This action cannot be undone.`,
          confirmationText: 'Discard changes',
        })
      ) {
        await updateElements(overwriteRecipe(element, recipe));
      }
    },
    [confirm, updateElements],
  );
};

export const useAllRecipeElementsInProject = (recipe?: Recipe): IElement[] => {
  const project = useProject();

  return useMemo(
    () => getAllRecipeElements(recipe, project),
    [project, recipe],
  );
};

export const useUpdateRecipeFromElement = (): ((
  recipe: Recipe,
  element: IElement,
) => Promise<Recipe>) => {
  const updateRecipe = useUpdateRecipe();

  return useCallback(
    async (recipe: Recipe, element: IElement) => {
      const updatedRecipeFromElement = updateRecipeFromElement(recipe, element);

      return updateRecipe(updatedRecipeFromElement);
    },
    [updateRecipe],
  );
};

/**
 * Get the recipe belonging to the element.
 * NOTE: ONLY USE WITHIN CALLBACKS since this won't update when the recipe changes
 * @param element
 * @param recipesLookup
 * @param products
 * @param version
 * @returns
 */
const getRecipeFromElement = (
  element: OneOfElements | undefined,
): Recipe | undefined => {
  const lookup = getRecipeLookup();
  const recipeId = resolveAutoRecipeId(getRecipeId(element));
  return recipeId ? lookup[recipeId] : undefined;
};

const recipeHasNewContent = (
  element: IElement,
  recipe: Recipe | undefined,
): boolean => {
  return recipe ? !isRecipeAndElementEqual(recipe, element) : false;
};

const isRecipeModified = (element: OneOfElements): boolean => {
  const recipe = isElement(element) && getRecipeFromElement(element);
  return recipe ? !isRecipeAndElementEqual(recipe, element) : false;
};

const hasRecipe = (element: OneOfElements): boolean => {
  const recipe = isElement(element) && getRecipeFromElement(element);
  return !!recipe;
};
